import React, { useEffect, useState } from 'react';
import { UnitDetailsStep, ContactStep, DocuSignStep, BillingStep, ProtectionPlanStep} from './bookingSteps/index';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from '../config';
import StepWrapper from './StepWrapper';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY, { locale: 'en' });

export default ({
    match,
    nextStep,
    prevStep,
    isFetching,
    isSuccessComplete,
}) => {
    const [step, setStep] = useState();

    useEffect(() => {
        setStep(+match.params.id);
    }, [match]);

    return (
        <Elements stripe={stripePromise} locale={'us'}>
            <div className="booking-steps">
                <div className="steps-controller">
                    <StepWrapper 
                        currentStep={step} 
                        defaultStep={1} 
                        isFetching={isFetching} 
                        title={'Unit Details and Payment Plan'}
                    >
                        <UnitDetailsStep nextStep={nextStep} prevStep={prevStep} />
                    </StepWrapper>
                    <StepWrapper 
                        currentStep={step} 
                        defaultStep={2} 
                        isFetching={isFetching} 
                        title={'Protection Plan'}
                    >
                        <ProtectionPlanStep nextStep={nextStep} prevStep={prevStep} />
                    </StepWrapper>
                    <StepWrapper 
                        currentStep={step} 
                        defaultStep={3} 
                        isFetching={isFetching} 
                        title={'Contact Details'} 
                        details={'Please provide your contact information.'}
                    >
                        <ContactStep nextStep={nextStep} prevStep={prevStep} />
                    </StepWrapper>
                    <StepWrapper 
                        currentStep={step} 
                        defaultStep={4} 
                        isFetching={isFetching} 
                        title={'Billing and Purchase'} 
                    >
                        <BillingStep
                            prevStep={prevStep}
                            completeBooking={nextStep}
                            isSuccessComplete={isSuccessComplete}
                        />
                    </StepWrapper>
                    <StepWrapper 
                        currentStep={step} 
                        defaultStep={5} 
                        isFetching={isFetching} 
                        title={'DocuSign Agreement'} 
                    >
                        <DocuSignStep nextStep={nextStep} prevStep={prevStep} />
                    </StepWrapper>
                </div>
            </div>
        </Elements>
    );
};
