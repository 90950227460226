import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={29}
            height={29}
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M16.035 15.121a.901.901 0 00-.9-.9h-.192a4.598 4.598 0 01-3.274-1.357 4.604 4.604 0 01-1.357-3.274 4.601 4.601 0 011.357-3.274 4.601 4.601 0 013.274-1.356 4.6 4.6 0 013.273 1.356 4.6 4.6 0 011.357 3.274v1.271a.689.689 0 01-1.154.462.685.685 0 01-.217-.453V9.591a3.254 3.254 0 00-2.518-3.2 3.253 3.253 0 00-3.685 1.738 3.254 3.254 0 00.872 3.98 3.252 3.252 0 003.938.14 2.482 2.482 0 004.563-1.376v-1.28a6.392 6.392 0 00-1.883-4.547 6.392 6.392 0 00-4.547-1.883 6.39 6.39 0 00-4.547 1.883 6.39 6.39 0 00-1.883 4.547 6.386 6.386 0 001.883 4.546 6.389 6.389 0 004.547 1.884h.192a.9.9 0 00.9-.902zm.369-5.53a1.463 1.463 0 01-2.273 1.216 1.457 1.457 0 01-.22-2.246 1.456 1.456 0 011.59-.316 1.455 1.455 0 01.903 1.345v.001z"
                fill="#E62222"
            />
            <path
                d="M28.566 10.691l-3.638-3.075V2.249a2.264 2.264 0 00-.659-1.59A2.265 2.265 0 0022.679 0H7.352a2.25 2.25 0 00-2.249 2.249v5.253L1.318 10.69a.9.9 0 00-.32.688v14.16a2.8 2.8 0 002.8 2.8h22.3a2.8 2.8 0 002.794-2.8v-14.16a.9.9 0 00-.326-.687zm-25.191.616l1.729-1.465v2.686l-1.729-1.22zm21.553 1.22V9.971l1.645 1.393-1.645 1.163zM6.904 2.25a.451.451 0 01.448-.447h15.33a.446.446 0 01.415.278.447.447 0 01.034.172v11.539l-8.115 5.745-8.112-5.745V2.25zM2.796 25.538v-12.44l8.144 5.76-5.9 4.518a.9.9 0 101.08 1.44l6.363-4.87 2.01 1.435a.9.9 0 001.04 0l2.009-1.435 6.366 4.861a.889.889 0 00.667.171.892.892 0 00.593-.351.9.9 0 00-.179-1.259l-5.893-4.509 7.994-5.653v12.336a1 1 0 01-.979 1H3.795a1.001 1.001 0 01-.998-1.004z"
                fill="#E62222"
            />
        </svg>
    )
}

export default SvgComponent
