import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={17}
            height={13}
            viewBox="0 0 17 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                opacity={0.45}
                d="M15.035.625H1.937C1.2.625.625 1.227.625 1.938v9.624c0 .739.574 1.313 1.313 1.313h13.097c.738 0 1.34-.574 1.34-1.313V1.938c0-.711-.602-1.313-1.34-1.313zM2.102 1.938h12.77c.108 0 .163.082.163.164V3.25H1.937V2.102c0-.082.055-.164.165-.164zm12.77 9.624H2.101c-.11 0-.164-.054-.164-.164V6.75h13.097v4.648c0 .11-.055.165-.164.165zM5.874 8.829a.353.353 0 00-.328-.328H3.578a.332.332 0 00-.328.328v1.094c0 .191.137.328.328.328h1.969a.332.332 0 00.328-.328V8.828zm5.25 0a.353.353 0 00-.328-.328H7.078a.332.332 0 00-.328.328v1.094c0 .191.137.328.328.328h3.719a.332.332 0 00.328-.328V8.828z"
                fill="#00173C"
            />
        </svg>
    )
}

export default SvgComponent
