import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.792 0H5.208A5.214 5.214 0 000 5.208v14.584A5.214 5.214 0 005.208 25h14.584A5.214 5.214 0 0025 19.792V5.208A5.214 5.214 0 0019.792 0zm3.125 19.792a3.129 3.129 0 01-3.125 3.125H5.208a3.129 3.129 0 01-3.125-3.125V5.208a3.129 3.129 0 013.125-3.125h14.584a3.129 3.129 0 013.125 3.125v14.584z"
        fill="#E62222"
      />
      <path
        d="M12.5 5.729a6.77 6.77 0 106.77 6.771 6.78 6.78 0 00-6.77-6.771zm0 11.459a4.688 4.688 0 114.686-4.688 4.694 4.694 0 01-4.687 4.688zM19.793 3.646a1.563 1.563 0 10-.002 3.126 1.563 1.563 0 00.002-3.126z"
        fill="#E62222"
      />
    </svg>
  )
}

export default SvgComponent
