import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={19}
            height={19}
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M8.397.633L6.112 5.309l-5.168.738C.03 6.187-.322 7.312.346 7.98l3.692 3.621-.88 5.098c-.14.914.845 1.617 1.653 1.195l4.606-2.425 4.57 2.426c.809.421 1.793-.282 1.652-1.196l-.879-5.098 3.692-3.62c.668-.669.316-1.794-.598-1.934l-5.133-.738-2.32-4.676c-.387-.809-1.582-.844-2.004 0z"
                fill="#00173C"
            />
        </svg>
    )
}

export default SvgComponent
