import { observable, action, decorate } from 'mobx';
import { BookingService } from '../../services/http';

export default class DocuSignStore {
    fields = {};
    docuSignUrl = null;
    history = null;
    isFetching = false;

    constructor(bookingStore) {
        this.bookingStore = bookingStore;
    }

    setHistory = history => {
        this.history = history;
    };

    init = () => {
        this.getDocuSign();
    };

    getDocuSign = async () => {
        this.isFetching = true;
        const { tenant, bookingId } = this.bookingStore;
        const response = await BookingService.getDocuSign(tenant.id, bookingId);
        if (response) {
            this.docuSignUrl = response.url;
        }
        this.isFetching = false;
    };

    onSubmit = async () => {
        this.history.push('/booking-success');
    };
}

decorate(DocuSignStore, {
    fields: observable,
    docuSignUrl: observable,
    history: observable,
    isFetching: observable,

    setHistory: action,
    getDocuSign: action,
}) 
