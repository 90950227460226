import React from 'react';

export default str => {
  let isRightSize;
  let sizeView;
  if (!!str && str.match(/\d*\.?\d*x\d*\.?\d*x\d*\.?\d*/)) {
    isRightSize = true;
    sizeView = str
      .split('x')
      .map((num, index) => {
        const t = ['’w ', '’d ', '’h '];
        return `${num}${t[index]}`;
      })
      .join('');
  } else {
    isRightSize = false;
    sizeView = str || '';
  }
  return sizeView.split('').map((symb, index) => {
    if (isRightSize && symb.match(/[a-zA-Z]/) && symb !== 'x') {
      return (
        <span className="little-letter" style={{ fontSize: '14px' }} key={index}>
          {symb}
        </span>
      );
    }
    return <React.Fragment key={index}>{symb}</React.Fragment>;
  });
};
