export const LOCATIONS_CONFIG = {
  // prod locations list
  45: {
    url: '/SohoStorage-129GrandSt/45',
    title: 'Storage Units at Grand & Crosby, Soho NYC - Local Locker Storage',
    description:
      'Convenient self storage units in Soho. Located on the corner of Grand and Crosby. Prices start at $85/mo. Call today to get discounts as a long term storer.',
  },
  46: {
    description:
      'Convenient self storage units on Riverside Blvd & 65th. Prices start at $110/mo and we offer discounts to long term storers. Accessible without any stairs. Call today!',
    title: 'Storage Units at Riverside & 65th NYC - Local Locker Storage',
    url: '/UWSStorage-100Riverside/46',
  },
  47: {
    description:
      'Convenient self storage units in Harlem. Call today about the first month free! Located on the corner of 119th & 5th. Prices start at $90/mo.',
    title: 'Storage Units Harlem NYC - Local Locker Storage',
    url: '/HarlemStorage-119th&5th/47',
  },
  48: {
    description:
      'Convenient self storage units in Brooklyn Heights. Call about the first month free! Located next to Cadman Plaza, elevator onsite. Prices start at $100/mo.',
    title: 'Storage Units Brooklyn Heights NYC - Local Locker Storage',
    url: '/BrooklynHeightsStorage/48',
  },
  49: {
    description:
      'Convenient self storage units in the East Village. Located on the corner of 12th & A. Prices start at $125/mo. Call us to get discounts as a long term storer.',
    title: 'Storage Units East Village NYC - Local Locker Storage',
    url: '/EastVillageStorage/49',
  },
  51: {
    description:
      'Convenient self storage units on the Upper West Side. Located on 80th & Broadway. Prices start at $125/mo. Call today to get discounts as a long term storer.',
    title: 'Storage Units at 80th & Broadway NYC - Local Locker Storage',
    url: '/UWSStorage-W80th/51',
  },
  52: {
    url: '/DowntownBrooklynStorage/52',
    description:
      'Convenient self storage units in Downtown Brooklyn. Call today about the first month free! Located just off Flatbush Ave. Prices start at $95/mo.',
    title: 'Storage Units Downtown Brooklyn NYC - Local Locker Storage',
  },
  53: {
    description:
      'Convenient self storage units on the Upper West Side. Located on the corner of 81st & Columbus. Prices start at $125/mo. Accessible without any stairs. Call today!',
    title: 'Storage Units at 81st & Columbus NYC - Local Locker Storage',
    url: '/UWSStorage-ColumbusAve/53',
  },
  54: {
    description:
      'Convenient self storage units in Williamsburg Brooklyn. Call about the first month free! Located on Kent, between N 3rd & N 4th. Prices start at $120/mo.',
    title: 'Storage Units at N3rd & Kent Williamsburg NYC - Local Locker Storage',
    url: '/WilliamsburgStorage-N3rd/54',
  },
  55: {
    url: "/Hell'sKitchenStorage/55",
    description:
      'Convenient self storage units in Midtown NYC. Call today about the first month free! Located on W 48th, between 9th & 10th. Prices start at $65/mo.',
    title: "Storage Units Hell's Kitchen Midtown NYC - Local Locker Storage",
  },
  58: {
    description:
      'Convenient self storage units in Williamsburg Brooklyn. Call about the first month free! Located on N 7th, between Wythe & Kent. Prices start at $115/mo.',
    title: 'Storage Units at N7th St in Williamsburg NYC - Local Locker Storage',
    url: '/WilliamsburgStorage-N7th/58',
  },
  59: {
    description:
      'Convenient self storage units in Flatbush Brooklyn. Call about the first month free! Located on Cortelyou, off Coney Island Ave. Prices start at $60/mo.',
    title: 'Storage Units Flatbush Brooklyn NYC - Local Locker Storage',
    url: '/FlatbushStorage/59',
  },
  60: {
    description:
      'Convenient self storage units in Park Slope Brooklyn. Call today about the first month free! Located on McGuinness Blvd. Prices start at $75/mo.',
    title: 'Storage Units Park Slope Brooklyn NYC - Local Locker Storage',
    url: '/ParkSlopeStorage/60',
  },
  61: {
    description:
      'Convenient self storage units in Williamsburg Brooklyn. Call today about the first month free! Located on Grand & Borinquen. Prices start at $100/mo.',
    title: 'Storage Units on Grand St in Williamsburg NYC - Local Locker Storage',
    url: '/WilliamsburgStorage-Grand/61',
  },
  62: {
    description:
      'Convenient self storage units on the Upper East Side. Call today about the first month free! Located on the corner of 84th & 1st. Prices start at $85/mo.',
    title: 'Storage Units Upper East Side NYC - Local Locker Storage',
    url: '/UpperEastSideStorage/62',
  },
  63: {
    description:
      'Convenient self storage units in Clinton Hill, Brooklyn. Call today about the first month free! Located on Myrtle Ave. Prices start at $65/mo.',
    title: 'Storage Units Clinton Hill Brooklyn NYC - Local Locker Storage',
    url: '/ClintonHillStorage/63',
  },
  64: {
    description:
      'Convenient self storage units in Greenpoint Brooklyn. Call today about the first month free! Located on McGuinness Blvd. Prices start at $70/mo.',
    title: 'Storage Units Greenpoint NYC - Local Locker Storage',
    url: '/GreenpointStorage/64',
  },
  65: {
    description:
      'Convenient self storage units on Riverside Blvd & 64th. Secure, monthly storage, close to home. Prices start at $50/mo. Get discounts as a long term storer.',
    title: 'Storage Units at 64th & Riverside NYC - Local Locker Storage',
    url: '/UWSStorage-80Riverside/65',
  },
  66: {
    url: '/240RiversideBlvd/66',
  },
  67: {
    description:
      'Convenient self storage units in Soho. Call today about the first month free! Located on the corner of Thompson & Broome. Prices start at $115/mo.',
    title: 'Storage Units at Thompson & Broome, Soho NYC - Local Locker Storage',
    url: '/SohoStorage-72Thompson/67',
  },
  68: {
    url: '/WilliamsburgStorage(McCarrenHotel)/68',
  },
};
