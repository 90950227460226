import { observable, action, decorate } from "mobx";
import Field from "../../utils/entities/Field";
import { TenantService } from "../../services/http";
import { stateOptions } from "../../utils/selectOptions";
import moment from "moment";
import { BookingService } from "../../services/http";
import bookingStore from "./BookingStore";

export default class ContactStore {
  fields = {
    firstName: new Field("text", true),
    lastName: new Field("text", true),
    businessName: new Field("text", false),
    city: new Field("text", true),
    phone: new Field("phone", true),
    email: new Field("email", true),
    address: new Field("text", true),
    state: new Field("text", true),
    postal: new Field("text", true),
    apartment: new Field("text", false),
    aboutUs: new Field("text", true),
  };
  history = null;

  constructor(bookingStore) {
    this.bookingStore = bookingStore;
  }

  setHistory = (history) => {
    this.history = history;
  };

  init = () => {
    this.fields.state.value = stateOptions[0].name || "";
  };

  selectState = (stateId) => {
    this.fields.state.value =
      stateOptions.find((state) => state.id === stateId)?.name || "";
  };

  setAddress = (address) => {
    !this.fields.address.value && (this.fields.address.value = address);
    if (address) {
      const city = address.split(",")[0];
      const stateId = address.split(",")[1].split(/\d/).join("").trim();
      if (stateId) {
        this.selectState(stateId);
      }
      if (city) {
        !this.fields.city.value && (this.fields.city.value = city);
      }
    }
  };

  destroy = () => {
    Object.keys(this.fields).forEach((key) => {
      this.fields[key].value = "";
      this.fields[key].error = "";
    });
  };

  onSubmit = async () => {
    const {
      firstName,
      lastName,
      businessName,
      city,
      phone,
      email,
      address,
      state,
      postal,
      aboutUs,
      apartment,
    } = this.fields;
    const stateId =
      stateOptions.find((st) => st.name === state.value)?.id || "";
    const { unitDetails, setTenantSubscriptionId } = this.bookingStore;
    const { unitDetailsStore, protectionPlanStore } = this.bookingStore;
    const { moveInDate, selectedPayment } = unitDetailsStore;
    const { selectedPlan } = protectionPlanStore;

    const formData = new FormData();
    formData.append("first_name", firstName.value);
    formData.append("last_name", lastName.value);
    formData.append("email", email.value);
    formData.append("business_name", businessName.value);
    formData.append("phone", phone.value);
    formData.append("address_line_1", address.value);
    formData.append("city", city.value);
    formData.append("state", stateId);
    formData.append("postal", postal.value);
    formData.append("heard_about_us", aboutUs.value);
    formData.append("apartment", apartment.value);

    const tenant = await TenantService.createTenant(formData);
    // console.log('this.bookingStore.setTenant(tenant);', tenant);
    this.bookingStore.setTenant(tenant);

    try {
      // console.log('==============> try', tenant, BookingService.createBooking, unitDetails, selectedPayment )
      const bookingResponse = await BookingService.createBooking({
        tenant: tenant.id,
        unit: unitDetails.id,
        move_in_date: moment(moveInDate).format("YYYY-MM-DD"),
        payment_plan: selectedPayment.id,
        insurance_provider: this.bookingStore.protectionPlanStore.fileId,
      });
      // console.log('this.bookingStore.setBookingId(bookingResponse.id);', this.bookingStore, bookingResponse.id );
      this.bookingStore.setBookingId(bookingResponse.id);

      const tenantSubscriptionResponse =
        await BookingService.saveTenantSubscription({
          tenant: tenant.id,
          subscription: selectedPayment.id,
          booking_id: bookingResponse.id,
          protection_plan: selectedPlan.id,
        });
      setTenantSubscriptionId(tenantSubscriptionResponse.id);
    } catch (error) {
      if (error?.response?.data?.unit?.length) {
        bookingStore.billingStore.setError(
          "All available units are already booked."
        );
        this.history.push("/booking-error");
      }
    }
  };
}

decorate(ContactStore, {
  fields: observable,
  history: observable,

  setAddress: action,
  setHistory: action,
});
