import http from "./http";

export class TenantService {
  static async createTenant(data) {
    return http.post(`tenant/`, data);
  }

  static async createWaitList(formData) {
    return http.post("wait_list/", formData);
  }
}
