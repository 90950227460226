import React from 'react';
import { Table, ConfigProvider } from 'antd';
import { Button } from './Button.js';
import parseSize from '../utils/parseSize.js';

export default ({ joinWaitList, onBook, data, locationName, locationAddress }) => {
  const id = category =>
    category.units.find(
      unit => unit.status !== 'booked' && unit.offline_flag === false && !unit.is_hidden
    )?.id;

  const dataSource = data
    .filter(catg => (catg.status !== 'booked' || catg.join_waitlist) && !catg.is_hidden)
    .map(category => {
      return {
        id: id(category) || category.id,
        size: category.size,
        price: category.price,
        key: category.id,
        deals: '',
        status: category.status,
        join_waitlist: category.join_waitlist,
        locationName: locationName,
        locationAddress: locationAddress,
      };
    });

  const columns = [
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      render: (text, record) => parseSize(record.size),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      // defaultSortOrder: 'descend', // sort available first
      sorter: (a, b) => a.status < b.status,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      defaultSortOrder: 'ascend', // sort min price first

      sorter: (a, b) => {
        return Number(a.price) - Number(b.price);
      },
      render: (text, record) => {
        return <>{'$' + text.split('.')[0]}</>;
      },
    },
    {
      title: 'Deals',
      key: 'deals',
      render: (text, record) => {
        if (record.status === 'booked') {
          if (record.join_waitlist) {
            return (
              <Button
                text="Join Waitlist"
                addClass="waitlist-btn"
                onClick={joinWaitList(record.key)}
              />
            );
          } else {
            return <></>;
          }
        }
        return <Button text="Book Now" addClass="book-btn" onClick={onBook(record)} />;
      },
    },
  ];

  const customizeRenderEmpty = () => (
    <div className="not-found">
      <p>No Units found!</p>
    </div>
  );

  return (
    <div className="units-table">
      <ConfigProvider renderEmpty={!data?.length && customizeRenderEmpty}>
        <Table dataSource={dataSource} columns={columns} pagination={false}></Table>
      </ConfigProvider>
    </div>
  );
};
