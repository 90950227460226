import React, { useEffect } from 'react';
import { Input } from './Input';
import { Button } from './Button';
import { observer } from 'mobx-react';
import FileIcon from '../assets/icons/FileIcon';
import { MAX_MB_LIMIT } from '../stores/bookingStores/ProtectionPlanStore';

export default observer(({ onClose, onSubmit, onChangeInput, onChangeFile, file, error, insuranceProvider }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  console.log("RENDER UPLOAD MODAL", error);

  return (
    <div
      className="modal-mask"
      onClick={() => {
        onClose();
      }}
    >
      <div
        className="upload-modal"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <span className="close-btn" onClick={onClose}>
          &times;
        </span>
        <div className="modal-title">
          <h3>Upload Insurance</h3>
        </div>
        <div className="modal-form">
          <div className="name-inputs">
            <Input
              label="Insurance Provider"
              name="insuranceProvider"
              onInput={(e) => {onChangeInput(e.target.value)}}
              borderColor={error === 'Please specify your insurance provider.' ? 'red' : '#00173c' }
              addClass="insurance-input"
              value={insuranceProvider}
            />
          </div>
          <label className='upload-label'>Insurance Provider</label>
          <div className='upload-file-wrapper' 
            style={{borderColor: error === 'Please upload the file with the insurance document.' || error === `The size of the uploaded file cannot be more than ${MAX_MB_LIMIT} MB.`? 'red' : '#00173c'}}
            onDragOver={(e) => {e.preventDefault()}} 
            onDragEnter={(e) => {e.preventDefault()}}
            onClick={((e) => {
              document.getElementById('file')?.click();
            })}
            onDrop={(e) => {
              const dT = new DataTransfer();
              dT.items.add(e.dataTransfer.files[0]);
              onChangeFile(dT.files[0]);
              e.preventDefault()
            }}
          > {!!file ? (<p><FileIcon /> {file?.name}</p>) : (<p>Upload Insurance Document</p>)}
            <input id='file' type='file' onChange={(e) => {
              console.log(e.target.files[0]);
              onChangeFile(e.target.files[0]);
              e.preventDefault()
            }}/>
          </div>
          <div className="modal-info">
            <p>
              By waiving our protection plan and relying on your private insurance you are agreeing that Local Locker will not be involved or held responsible in any claims regarding your stored items. 
            </p>
          </div>
          <div className='error'>{error}</div>
          <div className="submit-btn">
            <Button text="Cancel" addClass="booking-navigation-btn back" onClick={onClose} />
            <Button
              text='Submit'
              addClass="submit"
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
