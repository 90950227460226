import React from 'react';

export default ({ label, text, link }) => {
  return (
    <div className="additional-info">
      <div className="red-label">{label}</div>
      {link ? (
        <a className="additional-text" href={`tel: +1${text}`}>
          {text}
        </a>
      ) : (
        <div className="additional-text">{text}</div>
      )}
    </div>
  );
};
