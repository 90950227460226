import axios from 'axios';

import { BookingService } from './BookingService';
import { TenantService } from './TenantService';
import { LocationService } from './LocationService';
import { BASE_URL, TOKEN } from '../config';

const http = axios.create({
  baseURL: BASE_URL
});

http.interceptors.request.use(
  config => {
    config.headers['Authorization'] = `Token ${TOKEN}`;
    return config;
  },
  error => Promise.reject(error)
);

http.interceptors.response.use(
  response => response.data,
  error => Promise.reject(error)
);

export { BookingService, LocationService, TenantService };

export default http;
