import React from 'react';

export default ({ price, title, onClick, isSelected, centerCard, description, mostPopular }) => {
  return (
    <div
      className={`plan-card ${centerCard ? 'center-card' : ''} ${
        isSelected ? 'selected' : ''
      }`.trim()}
      style={{paddingTop: !!mostPopular ? '22px' : '49px'}}
      onClick={onClick}
    >
      <div className="card-title">{!!mostPopular && (<><span>Most Popular</span><br/></>) }{title}</div>
      <div className="price">
        ${price}
        <span>per month</span>
      </div>
      <div className="descripton">
        {description}
      </div>
    </div>
  );
};
