import { errorLiterals } from './errorLiterals';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

class Validator {
  validatorEmail = value => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const result = !regexp.test(String(value).toLowerCase());
    if (result) return errorLiterals.email.invalid;
    return '';
  };

  phoneValidation = value => {
    const regexp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    const result = !regexp.test(value);
    if (!isPossiblePhoneNumber(value) || result) return errorLiterals.phone.invalid;
    return '';
  };

  simpleValidation = value => {
    if (!value) return `Field is required`;
    return '';
  };

  validationField = (type, value) => {
    if (this.validationType[type]) {
      if (value) {
        return this.validationType[type](value);
      } else {
        return `Field is required`;
      }
    } else {
      return this.simpleValidation(value);
    }
  };

  validationType = {
    email: this.validatorEmail,
    phone: this.phoneValidation,
    password: this.simpleValidation,
    firstName: this.simpleValidation,
    lastName: this.simpleValidation,
    city: this.simpleValidation,
    address: this.simpleValidation,
    state: this.simpleValidation,
    postal: this.simpleValidation,
    aboutUs: this.simpleValidation
  };
}

export default new Validator();
