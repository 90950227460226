import React from 'react';
import Router from './router';

export default () => {
  return (
    <div className="App">
      <Router />
    </div>
  );
};
