import React from 'react';
import { DoneIcon } from '../assets/icons';
import Spinner from '../components/Spinner';

export default ({currentStep, defaultStep, children, title, isFetching, details}) =>  (
    <div className={`step ${currentStep === defaultStep ? 'active' : currentStep > defaultStep ? 'finished' : ''}`}>
        <div className="number-line">
            <div className="step-number">
                <span>{currentStep > defaultStep ? <DoneIcon /> : defaultStep}</span>
            </div>
            <div className="vl"></div>
        </div>

        <div className="step-content">
            <div className="step-name">
                <h2>{title}</h2>
            </div>
            {currentStep === defaultStep && (
                <div className="content">
                    <div className="content-main">
                        {!!details && <div className="details">{details}</div>}
                        {isFetching ? (
                            <Spinner />
                        ) : (
                            children
                        )}
                    </div>
                </div>
            )}
        </div>
    </div>
)
