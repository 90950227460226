export const calculateDiscount = (payments) => {
  const basePrice = payments.find((payment) => payment.duration === 1)
    ?.base_price;
  return payments.map((payment) => {
    const discount = Math.round(
      (basePrice - payment.base_price) * payment.duration
    );
    return {
      ...payment,
      discount,
    };
  });
};

export const parseUrl = (url) => {
  const param = url.split("/").slice(-1)[0];

  if (/[0-9]/i.test(param)) {
    return {
      zip: true,
      param,
    };
  } else {
    return {
      zip: false,
      param,
    };
  }
};
