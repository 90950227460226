import React, { useRef, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react';

import { Button } from '../Button';
import { Input } from '../Input';
import InputSelect from '../InputSelect';
import { stateOptions, aboutUsOptions } from '../../utils/selectOptions';
import getAddressFromZip from '../../utils/getAddressFromZip';
import bookingStore from '../../stores/bookingStores/BookingStore';
import { useHistory } from 'react-router-dom';

export default observer(({ nextStep, prevStep }) => {
  const { fields, setAddress, setHistory } = bookingStore.contactStore;
  const { step } = bookingStore;
  const [isTablet, setSize] = useState(false);
  const history = useHistory();

  const delayedCallback = useRef(
    debounce(async event => {
      const response = await getAddressFromZip(event.target.value);
      if (response?.data?.results?.length) {
        setAddress(response.data.results[0].formatted_address);
      } else {
        setAddress('');
      }
    }, 1000)
  ).current;

  useEffect(() => {
    checkSize();
    setHistory(history);
    window.addEventListener('resize', checkSize);
    return () => {
      window.removeEventListener('resize', checkSize);
    };
  }, []);

  const checkSize = () => {
    setSize(window.innerWidth <= 1024);
  };

  const onChangePostalCode = event => {
    event.persist();
    delayedCallback(event);
    fields.postal.onChange(event);
  };

  const tabIndxs = window.innerWidth > 768 ? [1, 2, 4, 6, 9, 3, 5, 7, 8, 10] : [];

  // console.log(">>>> fields", fields);

  return (
    <form>
      <div className="step-container first">
        <div className="title">
          <h2>Personal information</h2>
        </div>
        <div className="booking-form" id={`step-${step}`}>
          <div className="left-side">
            <div className="name-inputs">
              <Input
                label="First name"
                name="firstName"
                onInput={fields.firstName.onChange}
                error={fields.firstName.error}
                value={fields.firstName.value}
                tabIndex={tabIndxs[0]}
              />
              <Input
                label="Last name"
                name="lastName"
                onInput={fields.lastName.onChange}
                error={fields.lastName.error}
                value={fields.lastName.value}
                tabIndex={tabIndxs[1]}
              />
            </div>
            <div className="info-inputs">
              {isTablet ? (
                <>
                  <Input
                    label="Email"
                    name="email"
                    onInput={fields.email.onChange}
                    error={fields.email.error}
                    value={fields.email.value}
                    tabIndex={tabIndxs[5]}
                  />
                  <Input
                    label="Business Name"
                    subLabel="(Optional)"
                    name="businessName"
                    onInput={fields.businessName.onChange}
                    error={fields.businessName.error}
                    value={fields.businessName.value}
                    tabIndex={tabIndxs[2]}
                  />
                  <Input
                    label="Address"
                    name="address"
                    onInput={fields.address.onChange}
                    error={fields.address.error}
                    value={fields.address.value}
                    tabIndex={tabIndxs[6]}
                  />
                  <Input
                    label="Apartment"
                    name="apartment"
                    subLabel="(Optional)"
                    onInput={fields.apartment.onChange}
                    error={fields.apartment.error}
                    value={fields.apartment.value}
                    tabIndex={tabIndxs[7]}
                  />
                </>
              ) : (
                <>
                  <Input
                    label="Business Name"
                    subLabel="(Optional)"
                    name="businessName"
                    onInput={fields.businessName.onChange}
                    error={fields.businessName.error}
                    value={fields.businessName.value}
                    tabIndex={tabIndxs[2]}
                  />
                  <Input
                    label="City"
                    name="city"
                    onInput={fields.city.onChange}
                    error={fields.city.error}
                    value={fields.city.value}
                    tabIndex={tabIndxs[3]}
                  />
                  <Input
                    label="Phone"
                    type="tel"
                    name="phone"
                    onInput={fields.phone.onChange}
                    error={fields.phone.error}
                    value={fields.phone.value}
                    tabIndex={tabIndxs[4]}
                  />
                </>
              )}
            </div>
          </div>
          <div className="right-side">
            <div className="info-inputs">
              {isTablet ? (
                <>
                  <Input
                    label="City"
                    name="city"
                    onInput={fields.city.onChange}
                    error={fields.city.error}
                    value={fields.city.value}
                    tabIndex={tabIndxs[3]}
                  />
                </>
              ) : (
                <>
                  <Input
                    label="Email"
                    name="email"
                    onInput={fields.email.onChange}
                    error={fields.email.error}
                    value={fields.email.value}
                    tabIndex={tabIndxs[5]}
                  />
                  <div className="adress-inputs">
                    <Input
                      label="Address"
                      name="address"
                      onInput={fields.address.onChange}
                      error={fields.address.error}
                      value={fields.address.value}
                      tabIndex={tabIndxs[6]}
                    />
                    <Input
                      label="Apartment"
                      name="apartment"
                      subLabel="(Optional)"
                      onInput={fields.apartment.onChange}
                      error={fields.apartment.error}
                      value={fields.apartment.value}
                      tabIndex={tabIndxs[7]}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="adress-inputs">
              <InputSelect
                optionsList={stateOptions}
                label="State"
                name="state"
                handleChange={fields.state.onChange}
                error={fields.state.error}
                value={fields.state.value}
                tabIndex={tabIndxs[8]}
              />
              <Input
                label="Postal"
                name="postal"
                onInput={onChangePostalCode}
                error={fields.postal.error}
                value={fields.postal.value}
                tabIndex={tabIndxs[9]}
              />
            </div>
            {isTablet && (
              <Input
                label="Phone"
                type="tel"
                name="phone"
                onInput={fields.phone.onChange}
                error={fields.phone.error}
                value={fields.phone.value}
                tabIndex={tabIndxs[4]}
              />
            )}
            <div className="about-us-select">
              <InputSelect
                optionsList={aboutUsOptions}
                onFocus={event => {
                  event.target && event.target.scrollIntoView({ behavior: 'smooth' });
                }}
                label="How did you hear about us?"
                name="aboutUs"
                handleChange={fields.aboutUs.onChange}
                error={fields.aboutUs.error}
                value={fields.aboutUs.value}
                tabIndex={tabIndxs[10]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="navigation-btns">
        <Button text="Back" addClass="booking-navigation-btn back" onClick={prevStep} />
        <Button text="Continue" addClass="booking-navigation-btn continue" onClick={nextStep} />
      </div>
    </form>
  );
});
