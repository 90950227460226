import React, { useEffect } from "react";
import Template from "../components/Template";
import { CardIcon } from "../assets/icons";
import { ACHIcon } from "../assets/icons";
import { Input } from "../components/Input";
import InputSelect from "../components/InputSelect";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLISHABLE_KEY } from "../config";
import { observer } from "mobx-react";
import paymentStore from "../stores/paymentStore";
import { Button } from "../components/Button";
import { useHistory } from "react-router-dom";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import UnitUnfo from "../components/UnitUnfo";
import Spinner from "../components/Spinner";
import ReactGA from "react-ga";
import { PROD } from "../config";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY, { locale: "en" });

const PaymentPage = observer(() => {
  const {
    paymentOption,
    setPaymentOption,
    setStripe,
    creditFields,
    achFields,
    cardOnChange,
    onSubmit: onSubmitPayment,
    promocodeField,
    savePromocode,
    promocode,
    removePromocode,
    init,
    unitDetails,
    setHistory,
    subscription,
    moveInDate,
    isFetching,
    disabled,
    credit,
    isUpdateCard,
    onCardUpdate,
    protectionPlan
  } = paymentStore;
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  useEffect(() => {
    if (PROD) {
      ReactGA.event({
        category: "pageview",
        action: window.location.pathname,
        name: "pageview",
      });
    }
    setHistory(history);
    init();
  }, []);

  const selectOption = (num) => () => {
    setPaymentOption(num);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      console.warn(`Stripe.js has not loaded yet. Make sure to disable
      form submission until Stripe.js has loaded.`);
      return;
    }
    const cardElement = elements.getElement("cardNumber");
    setStripe(stripe, cardElement);
    onSubmitPayment();
  };

  if (isFetching) {
    return (
      <Template>
        <Spinner />
      </Template>
    );
  }

  // console.log(">>> PAYMENT PAGE", unitDetails);

  return (
    <Template>
      <div className="payment-page">
        <div className="payment-content">
          <div className="payment-info">
            <div className="payment-title">Payment Information</div>
            <div className="payment-select-box">
              <div
                className={`p-select-option ${
                  paymentOption === 0 ? "selected" : ""
                }`}
                onClick={selectOption(0)}
              >
                <CardIcon />
                <span className="option-label">Credit Card</span>
              </div>
              {/* <div
                                className={`p-select-option  ${paymentOption === 1 ? 'selected' : ''}`}
                                onClick={selectOption(1)}
                            >
                                <ACHIcon />
                                <span className="option-label">ACH</span>
                            </div> */}
            </div>
            <div className="payment-label">
              {/* Please fill in for adding a new card */}
            </div>
            <div className="payment-container">
              {paymentOption === 0 ? (
                <div className="credit-card-form">
                  <div className="two-inputs">
                    <Input
                      label="First Name"
                      name="firstName"
                      value={creditFields.firstName.value}
                      onInput={creditFields.firstName.onChange}
                      error={creditFields.firstName.error}
                    />
                    <Input
                      label="Last Name"
                      name="lastName"
                      value={creditFields.lastName.value}
                      onInput={creditFields.lastName.onChange}
                      error={creditFields.lastName.error}
                    />
                  </div>
                  <Input
                    label="Card Number"
                    name="cardNumber"
                    placeholder="xxxx  xxxx  xxxx  xxxx"
                    isCardNumber
                    onInput={cardOnChange}
                    error={creditFields.cardNumber.error}
                  />
                  <div className="two-inputs">
                    <Input
                      label="Expiration Date"
                      name="cardExpiry"
                      placeholder="MM / YY"
                      isExpiry
                      onInput={cardOnChange}
                      error={creditFields.cardExpiry.error}
                    />
                    <Input
                      label="CVC / CVV"
                      name="cardCvc"
                      isCVC
                      placeholder=""
                      onInput={cardOnChange}
                      error={creditFields.cardCvc.error}
                    />
                  </div>
                </div>
              ) : (
                <div className="ach-form">
                  <div className="two-inputs">
                    <Input
                      label="First Name"
                      name="firstName"
                      value={achFields.firstName.value}
                      onInput={achFields.firstName.onChange}
                      error={achFields.firstName.error}
                    />
                    <Input
                      label="Last Name"
                      name="lastName"
                      value={achFields.lastName.value}
                      onInput={achFields.lastName.onChange}
                      error={achFields.lastName.error}
                    />
                  </div>
                  <Input
                    label="Bank Name"
                    name="bankName"
                    onInput={achFields.bankName.onChange}
                    value={achFields.bankName.value}
                    error={achFields.bankName.error}
                  />
                  <InputSelect
                    optionsList={[
                      { id: 1, name: "Individual" },
                      { id: 2, name: "Company" },
                    ]}
                    handleChange={achFields.accountType.onChange}
                    value={achFields.accountType.value}
                    error={achFields.accountType.error}
                    name="accountType"
                    label="Account Type"
                  />
                  <Input
                    label="Account Number"
                    name="accountNumber"
                    placeholder="xxxxxxxxxxxx"
                    maxLength={12}
                    onInput={achFields.accountNumber.onChange}
                    value={achFields.accountNumber.value}
                    error={achFields.accountNumber.error}
                  />
                  <Input
                    label="Routing Number"
                    name="routingNumber"
                    placeholder="xxxxxxxxx"
                    maxLength={9}
                    onInput={achFields.routingNumber.onChange}
                    value={achFields.routingNumber.value}
                    error={achFields.routingNumber.error}
                  />
                </div>
              )}
            </div>
          </div>
          {!isUpdateCard && (
            <UnitUnfo
              promocodeField={promocodeField}
              savePromocode={savePromocode}
              promocode={promocode}
              removePromocode={removePromocode}
              unitDetails={unitDetails}
              subscription={subscription}
              moveInDate={moveInDate}
              credit={credit}
              protectionPlanPrice={protectionPlan.price}
            />
          )}
        </div>

        <div className="payment-buttons">
          <Button
            text="Submit"
            addClass="price-btn"
            onClick={onSubmit}
            disabled={disabled}
          />
        </div>
      </div>
    </Template>
  );
});

export default () => (
  <Elements stripe={stripePromise} locale={"us"}>
    <PaymentPage />
  </Elements>
);
