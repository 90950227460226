import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 215.152 215.152"
      {...props}
    >
      <path d="M107.573 0C61.849 0 24.649 37.206 24.649 82.938c0 32.665 13.675 65.706 39.545 95.549 19.32 22.288 38.47 34.917 39.275 35.443 1.246.815 2.675 1.222 4.104 1.222s2.857-.407 4.104-1.222c.806-.526 19.957-13.155 39.278-35.443 25.872-29.844 39.548-62.885 39.548-95.55C190.503 37.206 153.301 0 107.573 0zm-.007 198.447C92.281 187.083 39.649 143.54 39.649 82.938 39.649 45.477 70.12 15 107.573 15c37.457 0 67.93 30.477 67.93 67.938 0 60.463-52.661 104.121-67.937 115.509z" />
      <path d="M130.904 59.592a7.5 7.5 0 00-10.606 0l-12.72 12.719-12.721-12.723a7.5 7.5 0 00-10.606 0 7.5 7.5 0 00-.001 10.607l12.721 12.722L84.25 95.636a7.5 7.5 0 005.303 12.804 7.477 7.477 0 005.303-2.196l12.721-12.72 12.721 12.722a7.48 7.48 0 005.304 2.197 7.5 7.5 0 005.304-12.803l-12.721-12.722 12.72-12.718a7.502 7.502 0 00-.001-10.608z" />
    </svg>
  )
}

export default SvgComponent
