import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react';
import locationsStore from '../stores/LocationsStore';

const AppHead = observer(() => {
  const currentPath = window.location.href;
  const { location } = locationsStore;
  let title = 'Local Locker | NYC’s Storage Solution';
  let description = '';
  if (currentPath.match(/locations/)) {
    title = 'NYC Self Storage - Local Locker Storage';
    description =
      "New York City's most convenient storage solution, Local Locker is like an extra walk-in-closet right on your block.";
  }
  if (Number(currentPath.split('/')[2]) > -1 || Number(currentPath.split('/')[1]) > -1) {
    const name = location?.title || 'Local';
    title = `${name} Storage Solution - Local Locker Storage`;
    description = `${name} storage solution, Local Locker is like an extra walk-in-closet right on your block.`;
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
});

export default ({ children, withoutFooter }) => {
  return (
    <>
      <AppHead />
      <div className="template">
        <div className="main">
          <Sidebar />
          <div className="content">
            <Header />
            {children}
          </div>
        </div>
        {!withoutFooter && <Footer />}
      </div>
    </>
  );
};
