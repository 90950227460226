import { toast } from 'react-toastify';

class ToastsService {
  constructor() {
    const options = {
      position: 'top-left',
      hideProgressBar: true,
      className: 'toast',
      bodyClassName: 'toast-body'
    };
    this.showWarning = (text, customOptions) => {
      toast(text, {...options, ...customOptions});
    };
  }
}
export default new ToastsService();
