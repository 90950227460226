import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={15}
      height={13}
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.45}
        d="M14.063 3.25c0-.055-.083-.164-.137-.191L7.582.652C7.555.652 7.5.625 7.5.625c-.027 0-.082.027-.11.027L1.048 3.06c-.055.027-.11.136-.11.191v.438c0 .136.083.218.22.218h.655v.328c0 .192.137.329.329.329h10.718a.333.333 0 00.329-.329v-.328h.656c.11 0 .219-.082.219-.219V3.25zm-.657 8.313H1.594c-.383 0-.657.3-.657.656v.437c0 .137.083.219.22.219h12.687c.11 0 .219-.082.219-.219v-.437c0-.356-.301-.656-.657-.656zM3.125 5v5.25h-.984a.333.333 0 00-.329.328v.547h11.376v-.547a.353.353 0 00-.329-.328h-.984V5h-1.75v5.25h-1.75V5h-1.75v5.25h-1.75V5h-1.75z"
        fill="#00173C"
      />
    </svg>
  )
}

export default SvgComponent
