import React, { useState } from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { ArrowPrev } from '../assets/icons';
import { ArrowNext } from '../assets/icons';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ImageView from './ImageView';
import LazyLoad from 'react-lazyload';

export default ({ photos, location }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    if (!photos.length) {
        return (
            <div className="carousel">
                <ImageView className="slide-image" />
            </div>
        );
    }

    const onPrev = () => {
        currentSlide !== 0 ? setCurrentSlide(currentSlide - 1) : setCurrentSlide(photos.length - 1);
    };

    const onNext = () => {
        currentSlide !== photos.length - 1 ? setCurrentSlide(currentSlide + 1) : setCurrentSlide(0);
    };
    const selectSlide = (slide) => () => {
        photos.some((photo, index) => {
            if (photo.id === slide) {
                setCurrentSlide(index);
                return true;
            }
            return false;
        });
    };
    const bottomSlides = photos.filter((slide, index) => index !== currentSlide);

    return (
        <div className="carousel-main">
            <CarouselProvider
                naturalSlideWidth={480}
                naturalSlideHeight={300}
                totalSlides={photos.length}
                currentSlide={currentSlide}
                infinite
                touchEnabled={false}
                dragEnabled={false}
            >
                <Slider>
                    {photos.map((photo) => {
                        if (photo.id === 'GoogleMap') {
                            const Map = photo.component;
                            return (
                                <Slide index={photo.id} key={photo.id}>
                                    {<Map location={location} />}
                                </Slide>
                            );
                        }
                        return (
                            <Slide index={photo.id} key={photo.id}>
                                <LazyLoad height={300}>
                                    <ImageView
                                        className="slide-image"
                                        url={photo.photo}
                                        // mobileUrl={photo.mobile_photo}
                                        isImage
                                    />
                                </LazyLoad>
                            </Slide>
                        );
                    })}
                </Slider>
                <ButtonBack onClick={onPrev}>
                    <ArrowPrev alt="Icon Prev" />
                </ButtonBack>
                <ButtonNext onClick={onNext}>
                    <ArrowNext alt="Arrow Next" />
                </ButtonNext>
            </CarouselProvider>
            <CarouselProvider
                className="carousel-group"
                infinite
                touchEnabled={false}
                dragEnabled={false}
                naturalSlideWidth={480}
                naturalSlideHeight={87}
                visibleSlides={3}
                interval={4000}
                isPlaying={bottomSlides.length > 1}
                totalSlides={bottomSlides.length}
            >
                <Slider>
                    {bottomSlides.map((photo) => {
                        if (photo.id === 'GoogleMap') {
                            const Map = photo.component;
                            return (
                                <Slide index={photo.id} key={photo.id}>
                                    <div onClick={selectSlide(photo.id)}>
                                        {<Map location={location} withoutZoom />}
                                    </div>
                                </Slide>
                            );
                        }

                        return (
                            <Slide index={photo.id} key={photo.id}>
                                <LazyLoad height={87}>
                                    <ImageView
                                        className="panel-image"
                                        url={photo.photo}
                                        mobileUrl={photo.mobile_photo}
                                        onClick={selectSlide(photo.id)}
                                        isImage
                                    />
                                </LazyLoad>
                            </Slide>
                        );
                    })}
                </Slider>
            </CarouselProvider>
        </div>
    );
};
