import { observable, action, computed, decorate } from "mobx";

import Field from "../../utils/entities/Field";
import { BookingService } from "../../services/http";

export default class BillingStore {
  creditFields = {
    firstName: new Field("text", true),
    lastName: new Field("text", true),
    cardNumber: {
      error: "",
      name: "cardNumber",
    },
    cardExpiry: {
      error: "",
      name: "cardExpiry",
    },
    cardCvc: {
      error: "",
      name: "cardCvc",
    },
  };

  achFields = {
    firstName: new Field("text", true),
    lastName: new Field("text", true),
    bankName: new Field("text", true),
    bankAccount: new Field("text", true),
  };


  achBandAccountsOptions = [];

  cardElement = null;
  stripe = null;
  history = null;
  error = "";
  paymentSuccess = false;
  plaidPublicToken = null;
  plaidLinkToken = null;
  setAchInfo = (data) => {
    this.plaidPublicToken = data.public_token;
    this.achFields.bankName.value = data.institution?.name || "";
    this.achFields.bankName.error = undefined;

    this.achFields.bankAccount.label = data.accounts[0]?.name || "";
    this.achFields.bankAccount.value = data.accounts[0]?.id || "";
    this.achFields.bankAccount.error = undefined;
    this.achBandAccountsOptions = data.accounts.map((acc) => ({ name: acc.name, id: acc.id }));
  };

  constructor(bookingStore) {
    this.bookingStore = bookingStore;
  }

  get fields() {
    return this.paymentOptions === 0 ? this.creditFields : this.achFields;
  }

  promocodeField = new Field("text", false);
  promocode = null;
  promocodePending = false;

  paymentOptions = 1;

  disabled = false;

  cardOnChange = (event) => {
    this.creditFields[event.elementType].error = event.error
      ? event.error.message
      : "";
  };

  setError = (error) => {
    this.error = error;
  };

  setStripe = (stripe, cardElement, history) => {
    this.stripe = stripe;
    this.cardElement = cardElement;
    this.history = history;
  };

  removePromocode = () => {
    this.promocodeField.value = "";
    this.promocode = null;
  };

  setPaymentOptions = async (option) => {
    this.paymentOptions = option;
  };

  savePromocode = async () => {
    this.promocodeField.error = "";
    this.promocodePending = true;

    try {
      const response = await BookingService.promocode(
        this.promocodeField.value,
        this.bookingStore.subscriptions.id,
        this.bookingStore.bookingId
      );

      this.promocode = response;
    } catch (error) {
      this.promocodeField.error = "Provided code is not valid";
    } finally {
      this.promocodePending = false;
    }
  };

  init = async () => {
    this.tenant = this.bookingStore.tenant;
    if (this.paymentOptions === 1) {
      const response = await BookingService.obtainPlaidLinkToken(
        this.tenant.id
      );
      this.plaidLinkToken = response["link_token"];
    }

    if (!this.bookingStore.subscriptions) {
      this.bookingStore.getSubscriptions();
    }

    if (!this.bookingStore.tenant) {
      this.bookingStore.getTenant();
    }

    this.fields.firstName.value = this.bookingStore.tenant.first_name;
    this.fields.lastName.value = this.bookingStore.tenant.last_name;
  };

  onSubmit = async () => {
    let paymentId = null;
    this.disabled = true;
    if (this.paymentOptions === 0) {
      const result = await this.stripe.createToken(this.cardElement);
      this.bookingStore.isFetching = true;
      if (result.error) {
        this.bookingStore.isFetching = false;
        this.disabled = false;
        this.paymentSuccess = false;
        return false;
      }
      if (result.token) {
        try {
          const paymentResponse = await BookingService.addCardToken(
            this.tenant.id,
            result.token.id
          );
          paymentId = paymentResponse.id;
        } catch (err) {
          this.error = err.response.data.detail;
          this.bookingStore.isFetching = false;
          this.disabled = false;
          this.history.push({
            pathname: "/booking-error",
          });
          this.paymentSuccess = false;
          return false;
        }
      } else {
        this.bookingStore.isFetching = false;
        this.disabled = false;
        this.history.push("/booking-error");
        this.paymentSuccess = false;
        return false;
      }
    } else {
      if (this.plaidPublicToken && this.achFields.bankAccount?.value) {
        try {
          const paymentResponse = await BookingService.addCardToken(
            this.tenant.id,
            this.plaidPublicToken,
            this.achFields.bankAccount?.value,
            "ACH"
          );
          paymentId = paymentResponse.id;
        } catch (err) {
          this.error = err.response.data.detail;
          this.bookingStore.isFetching = false;
          this.disabled = false;
          this.history.push({
            pathname: "/booking-error",
          });
          this.paymentSuccess = false;
          return false;
        }
      } else {
        this.bookingStore.isFetching = false;
        this.disabled = false;
        this.history.push("/booking-error");
        this.paymentSuccess = false;
        return false;
      }
    }

    const formData = new FormData();
    formData.append("tenant_id", this.bookingStore.tenant.id);
    formData.append("subscription_id", this.bookingStore.subscriptions.id);
    formData.append("booking_id", this.bookingStore.bookingId);
    formData.append("payment_id", paymentId);
    formData.append(
      "tenant_subscription_id",
      this.bookingStore.tenantSubscriptionId
    );

    if (this.promocode !== null)
      formData.append("promocode_id", this.promocode.id);
    try {
      await BookingService.completeBooking(formData);
      this.bookingStore.isFetching = false;
      this.disabled = false;
      //   this.history.push('/booking-success');
      //   this.bookingStore.step = 1;
      //   this.bookingStore.isSuccessComplete = false;
      this.promocodeField = new Field("text", false);
      this.promocode = null;
      this.paymentSuccess = true;
      return true;
    } catch (err) {
      this.bookingStore.isFetching = false;
      this.disabled = false;
      this.error = err.response.data.error;
      this.history.push("/booking-error");
      this.paymentSuccess = false;
      return false;
    }
  };
}

decorate(BillingStore, {
  creditFields: observable,
  achFields: observable,
  cardElement: observable,
  stripe: observable,
  history: observable,
  error: observable,
  paymentSuccess: observable,
  fields: computed,
  promocodeField: observable,
  promocode: observable,
  paymentOptions: observable,
  disabled: observable,
  promocodePending: observable,
  plaidLinkToken: observable,
  plaidPublicToken: observable,
  achBandAccountsOptions: observable,

  setAchInfo: action,
  setError: action,
  setStripe: action,
  removePromocode: action,
  setPaymentOptions: action,
  savePromocode: action,
});
