import React from 'react';

class InputSelect extends React.Component {
  state = {
    defaultSelectText: '',
    showOptionList: false,
    optionsList: []
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.setState({
      defaultSelectText: this.props.defaultText
    });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = e => {
    if (
      !e.target.classList.contains('select-options') &&
      !e.target.classList.contains('custom-select-option') &&
      !e.target.classList.contains('selected-text')
    ) {
      this.setState({
        showOptionList: false
      });
    }
  };

  scrollTo = () => {
    this.props.scrollTo && this.props.scrollTo();
  }

  handleListDisplay = () => {
    this.setState(prevState => {
      return {
        showOptionList: !prevState.showOptionList
      };
    }, this.scrollTo);
  };

  handleOptionClick = event => {
    event.stopPropagation();
    const value = event.target.getAttribute('data-name');

    this.props.handleChange(value);

    this.setState({
      defaultSelectText: value,
      showOptionList: false
    });
  };

  render() {
    const { optionsList, onFocus, tabIndex } = this.props;
    const { showOptionList, defaultSelectText } = this.state;
    return (
      <div
        className={`custom-select-container ${this.props.error ? 'error' : ''}`.trim()}
        onClick={onFocus}
      >
        {this.props.label && <label>{this.props.label.trim()}</label>}
        <div
          className={showOptionList ? 'selected-text active' : 'selected-text'}
          onClick={this.handleListDisplay}
          name={this.props.name}
          tabIndex={tabIndex}
        >
          {this.props.value || defaultSelectText}
        </div>
        {showOptionList && (
          <ul className="select-options">
            {optionsList.map(option => {
              return (
                <li
                  className="custom-select-option"
                  data-name={option.name}
                  key={option.id}
                  onClick={this.handleOptionClick}
                >
                  {option.name}
                </li>
              );
            })}
          </ul>
        )}
        {this.props.error && <span>{this.props.error}</span>}
      </div>
    );
  }
}

export default InputSelect;
