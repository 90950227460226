import { useEffect } from 'react';

export const useOutClick = (ref, cb) => {
  useEffect(() => {
    const handleOutClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(event);
      }
    };

    window.addEventListener('click', handleOutClick);
    return () => {
      window.removeEventListener('click', handleOutClick);
    };
  }, [ref, cb]);
};
