import moment from 'moment';

export default ({fullMonthSum, creditSum, moveInDate, proratedFirstMonth}) => {
    console.log("moveInDate :",moveInDate);
    console.log("fullMonthSum: ", fullMonthSum);
    console.log("creditSum: ", creditSum);
    const addMonth = !!proratedFirstMonth ? 2 : 1;
    console.log("addMonth: ", addMonth);
    const additionalMonths = Math.floor(creditSum / fullMonthSum);
    console.log("additionalMonths: ", additionalMonths);
    const lastCredit = creditSum % fullMonthSum
    console.log("lastCredit: ", lastCredit);
    const sum = fullMonthSum - lastCredit;
    console.log("sum: ", sum);
    console.log("date: ",  moment(moveInDate).add(addMonth + additionalMonths, 'M').startOf('month').format('DD/MM/YY'));
    return {
        sum,
        date: moment(moveInDate).add(addMonth + additionalMonths, 'M').startOf('month').format('MM/DD/YY')
    }
}