import React from 'react';
import { FooterLogo } from '../assets/icons/';
import { FacebookLogo } from '../assets/icons/';
import { Instagram } from '../assets/icons/';

import { NavLink } from 'react-router-dom';
import { HOME_PAGE_URL } from '../config';

export default () => (
    <div className="footer">
        <div className="left-row">
            <div className="logo">
                <a href={HOME_PAGE_URL}>
                    <FooterLogo alt='Localocker Footer Logo' />
                </a>
            </div>
            <div className="left-side">
                <div>
                    <a href={`${HOME_PAGE_URL}faq/`}>FAQ's</a>
                </div>
                <div>
                    <a href={`${HOME_PAGE_URL}terms/`}>Terms</a>
                </div>
                <div>
                    <a href={`${HOME_PAGE_URL}privacy/`}>Privacy</a>
                </div>
            </div>
        </div>
        <div className="right-row">
            <div className="center">
                <div className="email">
                    <a href="mailto:reserve@localocker.com">reserve@localocker.com</a>
                </div>
                <div className="telephones">
                    <a href="tel:+1 (646) 713-0937">
                        <i>
                        Call or Text (646) 713-0937
                        </i>
                    </a>
                </div>
            </div>
            <div className="right-side">
                <div className="logos">
                    <a href="https://ndlr.cc/huGgl58uJ" target="_blank" rel="noopener noreferrer">
                        <FacebookLogo alt='Localocker Facebook Logo' />
                    </a>
                    <a
                        href="https://www.instagram.com/mylocalocker/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Instagram alt='Localocker Instagram Logo' />
                    </a>
                </div>
                <div>
                    <p>©2020 Local Locker</p>
                </div>
            </div>
        </div>
    </div>
);
