import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={11}
            height={11}
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                opacity={0.45}
                d="M9.912 0c-.107 0-.236.043-.365.086L.617 4.21c-1.03.473-.686 1.998.408 1.998h3.778V9.99c0 .645.537 1.01 1.052 1.01.365 0 .751-.172.944-.602l4.122-8.937C11.22.752 10.62 0 9.91 0zM5.833 9.99V5.178H1.047s-.022-.022 0-.043l8.886-4.082.022.021L5.833 9.99z"
                fill="#00173C"
            />
        </svg>
    )
}

export default SvgComponent