import React from 'react';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';

const REDIRECT_RULES = {
  // ['1473-fifth-ave-new-york-ny-10035']: '/Harlem/47',
  // ['451-columbus-ave-new-york-ny-10024']: '/451ColumbusAve/53',
  // ['186-montague-street-brooklyn-ny-11201']: '/BrooklynHeights/48',
  // ['441-e-12th-street-new-york-ny-10010']: '/EastVillage/49',
  // ['100-riverside-boulevard-new-york-ny-10069']: '/80RiversideBlvd/65',
  // ['209-west-80th-new-york-ny-10024']: '/West80thSt./51',
};

export default () => {
  const path = window.location.pathname.slice(1);
  const redirectPath = REDIRECT_RULES[path];
  let redirectFromOldLocation = path.match(/single-location/)
    ? path.replace('single-location/', '/')
    : false;
  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }
  if (redirectFromOldLocation) {
    return <Redirect to={redirectFromOldLocation} />;
  }
  return <Redirect to="/" />;
};
