import moment from 'moment';

export default (price, date) => {
  if(moment(date).date() === 1) return 0;
  if(!price) return price;
  const daysInMonth = () => {
    return new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
  };
  const days = moment(moment().endOf('month')).format('D') - moment(date).format('D') + 1;
  const sumPerDay = price / daysInMonth();
  const result = sumPerDay * days;
  return result;
};
