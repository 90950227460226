import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={48}
            height={49}
            viewBox="0 0 48 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g filter="url(#prefix__filter0_d)">
                <circle cx={24} cy={24} r={20} fill="#fff" />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26 22l-4-4-2 2 4 4-4 4 2 2 6-6-2-2z"
                fill="#E62222"
            />
            <defs>
                <filter
                    id="prefix__filter0_d"
                    x={0}
                    y={0.5}
                    width={48}
                    height={48}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy={0.5} />
                    <feGaussianBlur stdDeviation={2} />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0" />
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export default SvgComponent
