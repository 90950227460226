import { useEffect } from 'react';

export const useScroll = (cb) => {
  useEffect(() => {
    const handleOutClick = (event) => {
        cb(event);
    };

    window.addEventListener('scroll', handleOutClick);
    return () => {
      window.removeEventListener('scroll', handleOutClick);
    };
  }, [cb]);
};
