import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={56}
      height={56}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.15}
        d="M25.402 4.5c1.155-2 4.041-2 5.196 0l19.053 33c1.154 2-.289 4.5-2.598 4.5H8.947c-2.309 0-3.752-2.5-2.598-4.5l19.053-33z"
        fill="#E62222"
      />
      <path
        d="M30.55 31.334a2.037 2.037 0 00-2.032-2.03 2.021 2.021 0 00-2.03 2.03c0 1.143.888 2.032 2.03 2.032a2.02 2.02 0 002.032-2.032zm-3.834-10.308l.33 6.906a.629.629 0 00.61.559h1.7a.629.629 0 00.61-.559l.33-6.906a.614.614 0 00-.61-.66h-2.361a.614.614 0 00-.61.66z"
        fill="#E62222"
      />
    </svg>
  )
}

export default SvgComponent
