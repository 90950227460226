import React, { useEffect } from 'react';
import { Button } from '../Button';
import PaymentInformation from '../PaymentInformation';
import UnitUnfo from '../UnitUnfo';
import { observer } from 'mobx-react';
import bookingStore from '../../stores/bookingStores/BookingStore';
import { useHistory } from 'react-router-dom';
import { useStripe, useElements } from '@stripe/react-stripe-js';

export default observer(({ prevStep, completeBooking, isSuccessComplete }) => {
  const history = useHistory();
  const {
    billingStore: {
      paymentOptions,
      setPaymentOptions,
      promocodeField,
      savePromocode,
      promocode,
      removePromocode,
      achFields,
      creditFields,
      setStripe,
      cardOnChange,
      disabled,
      promocodePending
    },
    protectionPlanStore: {
      selectedPlan
    },
    step,
    subscriptions,
    unitDetails,
  } = bookingStore;
  const { moveInDate, duration } = bookingStore.unitDetailsStore;
  const stripe = useStripe();
  const elements = useElements();

  const onCompleteBooking = async event => {
    event.preventDefault();
    if (!stripe || !elements) {
      console.warn(`Stripe.js has not loaded yet. Make sure to disable
      form submission until Stripe.js has loaded.`);
      return;
    }
    const cardElement = elements.getElement('cardNumber');
    setStripe(stripe, cardElement, history);
    completeBooking(event);
  };

  return (
    <div className="fourth-step">
      <div className="fourth-step-content" id={`step-${step}`}>
        <PaymentInformation
          paymentOptions={paymentOptions}
          setPaymentOptions={setPaymentOptions}
          achFields={achFields}
          creditFields={creditFields}
          cardOnChange={cardOnChange}
        />
        <UnitUnfo
          promocodeField={promocodeField}
          savePromocode={savePromocode}
          subscription={subscriptions}
          promocode={promocode}
          removePromocode={removePromocode}
          unitDetails={unitDetails}
          moveInDate={moveInDate}
          duration={duration}
          protectionPlanPrice={selectedPlan.price}
          promocodePending={promocodePending}
        />
      </div>
      <div className="navigation-btns">
        <Button text="Back" addClass="booking-navigation-btn back" onClick={prevStep} />
        <Button
          text="Book Now"
          addClass="booking-navigation-btn continue"
          onClick={onCompleteBooking}
          disabled={disabled}
        />
      </div>
    </div>
  );
});
