import React, { useState, useEffect } from 'react';
import { Input } from './Input';
import { Button } from './Button';
import { NavigatorIcon } from '../assets/icons';
import { DoneIcon } from '../assets/icons';
import { observer } from 'mobx-react';
import parseSize from '../utils/parseSize';
import getProratedAmounth from '../utils/getProratedAmount';
import parseNumber from '../utils/parseNumber';
import ReactTooltip from "react-tooltip";
import calculateNextBilling from '../utils/calculateNextBilling';

export default observer(
    ({
        promocodeField,
        savePromocode,
        subscription,
        promocode,
        removePromocode,
        unitDetails,
        moveInDate,
        credit,
        duration: durationBooking,
        protectionPlanPrice = 0,
        promocodePending
    }) => {
        const [coupon, toggleCoupon] = useState(false);

        useEffect(() => {
            promocode ? toggleCoupon(true) : toggleCoupon(false);
        }, [promocode]);

        const proratedFirstMonth = getProratedAmounth((subscription?.base_price || 0) + protectionPlanPrice, moveInDate) ;

        const firstFullMonth =  subscription ?.duration === 1 ? subscription.base_price + protectionPlanPrice : 0; 

        const fullMonth = subscription?.base_price + protectionPlanPrice;

        // console.log('firstFullMonth', firstFullMonth, promocode?.promocode_type);

        const PROMOCODES_HANDLING = {
            ["First full month free"]: {
                discount:  fullMonth,
                display:  fullMonth,
                text: subscription ?.duration === 1 ? "First full month free" : '',
                sumForBillingCalc: 0,
            },
            ["Prorated month and first full month free"]: {
                discount: subscription ?.duration === 1 ? proratedFirstMonth + fullMonth : fullMonth,
                display:  subscription ?.duration === 1 ? proratedFirstMonth + fullMonth : fullMonth  ,
                text: subscription ?.duration === 1 ? "Prorated month and first full month free" : '',
                sumForBillingCalc: 0,
            },
            ["The prorated month free"]: {
                discount: subscription ?.duration === 1 ? proratedFirstMonth : 0,
                display:  subscription ?.duration === 1 ?  proratedFirstMonth : 0,
                text: subscription ?.duration === 1  ? "":"The prorated month free",
                sumForBillingCalc: 0,
            },
            ["First full month and next month free"]: {
                discount: subscription ?.duration === 1 ? fullMonth : fullMonth * 2,
                display:  fullMonth * 2,
                text: subscription ?.duration === 1 ? "First full month and next month free" : '',
                sumForBillingCalc: fullMonth,
            },
            ["Fixed amount, one time"]: {
                discount: promocode?.discount,
                display:  promocode?.discount,
                text: "",
                sumForBillingCalc: promocode?.discount,
            },
            ["Next Month Free"]: {
                discount: subscription ?.duration === 1 ? 0 : fullMonth,
                display:  fullMonth,
                text: subscription ?.duration === 1 ? "Next Month Free" : '',
                sumForBillingCalc: fullMonth,
            },
            ["Fixed sums"]: {
                discount: promocode?.discount,
                display:  promocode?.discount,
                text: "",
                sumForBillingCalc: promocode?.discount,
            }
        }

        const subTotal =
            subscription ?.duration === 1
                ? Number(proratedFirstMonth)  + firstFullMonth  
                : (subscription ?.base_price + protectionPlanPrice) * subscription ?.duration;
        
        // console.log("SUB TOTAL", subTotal, subscription ?.duration === 1, Number(proratedFirstMonth), );

        let duration = durationBooking || subscription ?.duration;

        let discountFromPromocode = PROMOCODES_HANDLING[promocode?.promocode_type]?.discount !== undefined ? PROMOCODES_HANDLING[promocode?.promocode_type]?.discount : promocode?.discount;

        let discount = (discountFromPromocode || 0) + (Number(credit) || 0)
        // console.log("DISCOUNT", discount, PROMOCODES_HANDLING[promocode?.promocode_type]?.discount, promocode?.discount, )

        let total = Number(subTotal - discount).toFixed(2);

        let sumForBillingCalcFromPromocode = PROMOCODES_HANDLING[promocode?.promocode_type]?.sumForBillingCalc !== undefined ? PROMOCODES_HANDLING[promocode?.promocode_type]?.sumForBillingCalc : promocode?.discount;

        let discountForNextBilling = (sumForBillingCalcFromPromocode || 0) + (Number(credit) || 0)
        // console.log('discountForNextBilling ',discountForNextBilling,'fullMonth ', fullMonth,'proratedFirstMonth ', proratedFirstMonth, 'discountForNextBilling - (fullMonth + proratedFirstMonth) ', discountForNextBilling - (fullMonth + proratedFirstMonth) );
        // console.log('(discountForNextBilling - (fullMonth + proratedFirstMonth) > 0) ? discountForNextBilling - (fullMonth + proratedFirstMonth) : 0 ', (discountForNextBilling - (fullMonth + proratedFirstMonth) > 0) ? discountForNextBilling - (fullMonth + proratedFirstMonth) : 0)

        let discountForNextBillingFixedAmount =  (discountForNextBilling - (fullMonth + proratedFirstMonth) > 0) ? discountForNextBilling - (fullMonth + proratedFirstMonth) : 0;
        // console.log('discountForNextBillingFixedAmount ', discountForNextBillingFixedAmount);
        // console.log('promocode?.promocode_type', promocode?.promocode_type)
        // console.log("FOR CREDIT", promocode?.promocode_type === "Fixed amount, one time" ? discountForNextBillingFixedAmount : discountForNextBilling)

        const nextBilling = subscription ?.duration === 1 ? calculateNextBilling({proratedFirstMonth, moveInDate, fullMonthSum: fullMonth, creditSum: promocode?.promocode_type === "Fixed amount, one time" || promocode?.promocode_type === "Fixed sums" ? discountForNextBillingFixedAmount : discountForNextBilling}) : null;

        // console.log("promocodePending", promocodePending)

        return (
            <div className="unit-rental-info">
                <div className="unit-details">
                    <h4>Unit Details</h4>
                    <p className="unit-size">{parseSize(unitDetails ?.size || '')}</p>
                    <p className="unit-address">
                        <NavigatorIcon />
                        {unitDetails ?.locationAddress || unitDetails ?.locationName}
                    </p>
                </div>
                <div className="rental-details">
                    <h4>Rental Details</h4>
                    <p className="rental-price">
                        <i>
                            ${parseNumber(subscription ?.base_price)} <sub>per month unit</sub>
                        </i>
                        <br/>
                        {!!protectionPlanPrice && (
                            <i>
                                ${parseNumber(protectionPlanPrice)} <sub>per month protection plan</sub>
                            </i>
                        )}
                        { subscription ?.duration === 1 && fullMonth && !!protectionPlanPrice && (
                            <p className='total-line'>
                                <i>
                                    ${fullMonth}<sub> per month total</sub>
                                </i>
                            </p>
                        )}
                        {!!duration && duration !== 1 && (
                            <p>
                                <i>
                                    <sub>For</sub> {duration} <sub>{'Months'}</sub>
                                </i>
                            </p>
                        )}
                    </p>

                    {coupon ? (
                        <div className="coupon">
                            <DoneIcon />
                            <span className="coupon-name">{promocodeField.value}</span>
                            <span> has been applied</span>
                            <div className="remove-btn" onClick={() => {
                                    removePromocode();
                                }}>
                                Remove
                            </div>
                        </div>
                    ) : (
                            <div className="rental-btn-group">
                                <Input
                                    placeholder="Enter Promo Code"
                                    onInput={promocodeField.onChange}
                                    value={promocodeField.value}
                                    error={promocodeField.error}
                                />
                                <Button
                                    text="Apply"
                                    addClass="apply-btn"
                                    isLoading={promocodePending}
                                    onClick={() => {
                                        savePromocode();
                                    }}
                                />
                            </div>
                        )}
                    {subscription ?.duration === 1 && !!proratedFirstMonth && (
                        <div className="rental-line">
                            <span>Prorated Month </span>
                            <span>${proratedFirstMonth.toFixed(2)}</span>
                        </div>
                    )}
                    {subscription ?.duration === 1 && (
                        <div className="rental-line">
                            <div><span>First Full Month </span><a className='tooltip-btn' data-tip data-for="whyTip">Why?</a></div>
                            <span>${fullMonth}</span>
                        </div>
                    )}
                    {subscription ?.duration === 1 && (
                        <div className="rental-line subtotal-line">
                            <span>Subtotal </span>
                            <span>${(fullMonth + proratedFirstMonth).toFixed(2)}</span>
                        </div>
                    )}
                    { (discount || !!promocode) && (
                            <div className="rental-line">
                                <div>
                                    <span>{"Promotion Discount"}</span>
                                    {PROMOCODES_HANDLING[promocode?.promocode_type]?.text && (
                                        <>
                                            <br/><span className='description'>
                                                {PROMOCODES_HANDLING[promocode?.promocode_type]?.text}
                                            </span>
                                        </>
                                    )}            
                                </div>
                                <span>
                                    -${PROMOCODES_HANDLING[promocode?.promocode_type]?.display?.toFixed(2) || discount.toFixed(2)}
                                </span>
                            </div>
                        )
                    }
                </div>
                <div className="rental-summary">
                    <span>Total</span>
                    <span>${Number.isNaN(total) || total < 0 ? '0.00' : total}</span>
                </div>
                {subscription ?.duration === 1 && (
                    <div className="next-billing-line">
                        <span>Next Billing </span>
                        <span>${nextBilling?.sum?.toFixed(2)} on {nextBilling?.date}</span>
                    </div>
                )}
                <ReactTooltip id="whyTip" place="top" effect="solid" type="info" event='click' multiline backgroundColor="#00173c" globalEventOff='click' >
                    You will be billed on the first of the month,<br/>
                    each month. At sign up, you are charged for <br/>
                    the prorated amount of time left in the current<br/>
                    month as well as the next upcoming full month.<br/>
                    This payment is taken in advance and applied<br/>
                    toward your next bill.
                </ReactTooltip>
            </div>
        );
    }
);
