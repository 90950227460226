import React from 'react';
import { ArrowBack } from '../assets/icons/';

export default ({ onClick, text }) => {
    return (
        <div className="back-button" onClick={onClick}>
            <ArrowBack />
            <span>{text}</span>
        </div>
    );
};
