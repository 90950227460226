import { observable, action, decorate } from 'mobx';
import { LocationService, TenantService } from '../services/http';
import Field from '../utils/entities/Field';
import validator from '../utils/validator';
import StorageService from '../services/StorageService';

class LocationsStore {
    locations = [];
    location = null;
    markerList = [];
    selectedLocation = null;
    modal = false;
    modalSucces = false;
    isFetching = false;
    isSearching = false;
    waitListUnitCategory = null;
    waitListData = {
        firstName: new Field('text', true),
        lastName: new Field('text', true),
        phone: new Field('phone', true),
        email: new Field('email', true),
        aboutUs: new Field('text', true),
    };
    activeMarker = '';
    isInitial = true;
    searchPath = '';

    checkForErrors = fields => !!Object.keys(fields).filter(field => fields[field].error).length;

    cutLocations = locations => {
        return locations.slice(0, 5);
    };

    getLocationsList = async (search, isSearch) => {
        this.markerList = [];
        !isSearch ? (this.isFetching = true) : (this.isSearching = true);
        const locations = await LocationService.getLocationList(search);
        this.locations = this.cutLocations(locations);
        !isSearch ? (this.isFetching = false) : (this.isSearching = false);
        this.isInitial = false;
    };

    setUnitWaitList = num => {
        this.waitListUnitCategory = num;
    };

    setMarkerList = locations => {
        this.markerList = locations;
    };

    hoverEffect = id => {
        this.activeMarker = this.markerList.find(marker => marker.id === id);
    };

    getLocation = async id => {
        this.isFetching = true;
        this.location = await LocationService.getLocation(id);
        this.isFetching = false;
    };

    setSearchPath = searchPath => {
        if (searchPath) {
            StorageService.setItem('searchPath', searchPath);
            this.searchPath = searchPath;
        } else {
            StorageService.setItem('searchPath', '');
            this.searchPath = '';
        }
    };

    toggleModal = bool => {
        this.modal = bool;
    };

    onSubmit = event => {
        event.preventDefault();
        const fields = this.waitListData;
        Object.keys(fields).forEach(
            field => (fields[field].error = validator.validationField(field, fields[field].value))
        );
        const isErrors = this.checkForErrors(this.waitListData);

        if (!isErrors) {
            // this.toggleModal(false);
            const formData = new FormData();
            formData.append('unit_category', this.waitListUnitCategory);
            formData.append('first_name', this.waitListData.firstName.value);
            formData.append('last_name', this.waitListData.lastName.value);
            formData.append('email', this.waitListData.email.value);
            formData.append('phone', this.waitListData.phone.value);
            formData.append('heard_about_us', this.waitListData.aboutUs.value);
            TenantService.createWaitList(formData);
            this.modalSucces = true;
        }
    };
}

decorate(LocationsStore, {
    locations: observable,
    location: observable,
    markerList: observable,
    selectedLocation: observable,
    modal: observable,
    modalSucces: observable,
    isFetching: observable,
    isSearching: observable,
    waitListUnitCategory: observable,
    waitListData: observable,
    activeMarker: observable,
    isInitial: observable,
    searchPath: observable,

    getLocationsList: action,
    setUnitWaitList: action,
    setMarkerList: action,
    hoverEffect: action,
    getLocation: action,
    setSearchPath: action,
    toggleModal: action,
    onSubmit: action,
})

const locationsStore = new LocationsStore();

export default locationsStore;
