import http from './http';

export class LocationService {
  static async getLocationList(search) {
    return await http.get('location/', { params: { search } });
  }

  static async getLocation(locationId) {
    return await http.get(`location/${locationId}`);
  }
}
