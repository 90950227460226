import React, { useEffect } from "react";
import LocationMainInfo from "../components/LocationMainInfo";
import UnitsTable from "../components/UnitsTable";
import CustomerReviewsTable from "../components/CustomerReviewsTable";
import WaitListModal from "../components/WaitListModal";
import { observer } from "mobx-react";
import LocationsStore from "../stores/LocationsStore.js";
import { useHistory } from "react-router-dom";
import Spinner from "../components/Spinner";
import GoogleMap from "../components/GoogleMap";
import bookingStore from "../stores/bookingStores/BookingStore";
import Template from "../components/Template";
import StorageService from "../services/StorageService";
import ReactGA from "react-ga";
import { PROD } from "../config";
import { Helmet } from "react-helmet";
import { LOCATIONS_CONFIG } from "../locations.config";

export default observer(() => {
  const {
    modal,
    isFetching,
    toggleModal,
    location,
    getLocation,
    setUnitWaitList,
  } = LocationsStore;
  const { setUnitDetails, setLocationPath, locationPath } = bookingStore;
  const history = useHistory();

  useEffect(() => {
    if (PROD) {
      ReactGA.event({
        category: "pageview",
        action: window.location.pathname,
        name: "pageview",
      });
    }
    let pathname = history.location.pathname;
    if (pathname[pathname.length - 1] === "/")
      pathname = pathname.slice(0, pathname.length - 1);
    const locationId = pathname.split("/").splice(-1);
    const locationPath = pathname.split("/").splice(-2).join("/");
    setLocationPath(locationPath);
    bookingStore.step = 1;
    getLocation(locationId);

    // eslint-disable-next-line
  }, []);

  const onBackHandler = () => {
    const searchPath = StorageService.getItem("searchPath");

    history.push(`/locations/${searchPath || LocationsStore.searchPath || ""}`);
  };
  const onCloseModalHandler = () => {
    toggleModal(false);
    LocationsStore.modalSucces = false;
  };
  const onBookHandler = (record) => () => {
    const data = record;
    console.log("onBookHandler setUnitDetails");
    setUnitDetails(data);
    console.log("==========> DATA", data);
    delete data.deals;
    delete data.key;
    const urlParams = new URLSearchParams({
      ...data,
      book_now: true,
      locationPath: locationPath,
    }).toString();
    console.log("==========> urlParams", urlParams);
    history.push(`/booking/?${urlParams}`);
  };
  const setUnitWautListHandler = (num) => () => {
    setUnitWaitList(num);
    toggleModal(true);
  };
  const getAccessHours = () => {
    const date = new Date();
    const day = date.getDay();
    const week = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];

    return week[day];
  };
  const createMetaTags = () => {
    let pathname = history.location.pathname;
    const locationId =
      pathname[pathname.length - 1] === "/"
        ? pathname.split("/")[pathname.split("/").length - 2]
        : pathname.split("/").splice(-1);
    const locationConfig = LOCATIONS_CONFIG[locationId];
    const metaTitle = locationConfig && locationConfig.title;
    const metaDescription = locationConfig && locationConfig.description;
    const metaCanonical =
      "https://booking.localocker.com" + window.location.pathname;

    return (
      <Helmet>
        <link rel="canonical" href={metaCanonical} />
        {metaTitle && <title>{metaTitle}</title>}
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
      </Helmet>
    );
  };

  if (isFetching || !location)
    return (
      <Template>
        {createMetaTags()}
        <Spinner />
      </Template>
    );

  const {
    title,
    access_hours,
    telephone,
    address,
    photos,
    all_location_reviews_and_rating: {
      reviews,
      total_reviews_count,
      average_rating_all_locations,
    },
    location_rating,
    location_reviews_count,
    details,
    unit_categories,
    book_tour_link,
    direction_link,
  } = location;

  const photosWithMap = [
    {
      id: "GoogleMap",
      component: GoogleMap,
    },
    ...photos,
  ];

  return (
    <Template>
      {createMetaTags()}
      <div className="single-location-page">
        <LocationMainInfo
          name={title}
          rating={location_rating}
          address={address}
          details={details}
          accessHours={access_hours[getAccessHours()]}
          number={telephone}
          images={photosWithMap}
          numberReviews={location_reviews_count}
          onBack={onBackHandler}
          bookTourLink={book_tour_link}
          location={location}
          directionLink={direction_link}
        />
        <a name="prices">
          <UnitsTable
            data={unit_categories}
            joinWaitList={setUnitWautListHandler}
            onBook={onBookHandler}
            locationName={title}
            locationAddress={address}
          />
        </a>
        <a name="rating">
          <CustomerReviewsTable
            reviews={reviews}
            total={total_reviews_count}
            totalRating={average_rating_all_locations}
          />
        </a>
        {modal && <WaitListModal onClose={onCloseModalHandler} />}
      </div>
    </Template>
  );
});
