import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={14}
            height={16}
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                opacity={0.5}
                d="M12.156 2.75H10.75V1.227a.378.378 0 00-.352-.352H9.227a.356.356 0 00-.352.352V2.75h-3.75V1.227a.378.378 0 00-.352-.352H3.602a.356.356 0 00-.352.352V2.75H1.844c-.791 0-1.407.645-1.407 1.406V14.47c0 .79.616 1.406 1.407 1.406h10.312c.762 0 1.406-.615 1.406-1.406V4.156c0-.761-.644-1.406-1.406-1.406zm-.176 11.719H2.02c-.118 0-.176-.059-.176-.176v-8.73h10.312v8.73c0 .117-.088.176-.176.176z"
                fill="#00173C"
            />
        </svg>
    )
}

export default SvgComponent
