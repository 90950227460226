import React, { useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import bookingStore from "../stores/bookingStores/BookingStore";
import { observer } from "mobx-react";
import { Input } from "./Input";
import Spinner from "../components/Spinner";
import { Button } from "./Button";

const AchPlaid = observer(() => {
  const {
    billingStore: {
      plaidLinkToken,
      achFields,
      setAchInfo,
      achBandAccountsOptions,
    },
  } = bookingStore;

  const onSuccess = (publicToken, metadata) => {
    setAchInfo(metadata);
  };

  const onEvent = (eventName, metadata) => {
    console.log(eventName);
  };

  const config = {
    token: plaidLinkToken,
    onSuccess,
    onEvent,
  };
  const { open, ready } = usePlaidLink(config);
  return (
    <div>
      {ready ? (
        <div className="achform">
          <div className="navigation-btns" style={{ justifyContent: "left" }}>
            <Button
              text="Connect Bank"
              addClass="booking-navigation-btn continue"
              onClick={open}
              style={{ width: "100%", height: "36px", borderRadius: "10px", marginLeft: "0px"}}
            />
          </div>
          <Input
            label="Bank Name"
            name="bankName"
            onInput={achFields.bankName.onChange}
            value={achFields.bankName.value}
            error={achFields.bankName.error}
            disabled={true}
          />

          <Input
            label="Bank account"
            name="bankAccount"
            onInput={achFields.bankAccount.onChange}
            value={achFields.bankAccount.value}
            error={achFields.bankAccount.error}
            disabled={true}
            options={achBandAccountsOptions}
          />

        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
});

export default AchPlaid;
