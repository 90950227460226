import React, { useState, useEffect } from "react";
import { SearchIcon } from "../assets/icons";
import { NavigatorIcon } from "../assets/icons";
import { Logo } from "../assets/icons";
import { ViberContact } from "../assets/icons";
import { MessageContact } from "../assets/icons";
import { EmailContact } from "../assets/icons";
import { SubLogo } from "../assets/icons";
import { BurgerMenu } from "../assets/icons";
import { Close } from "../assets/icons";

import { observer } from "mobx-react";
import locationsStore from "../stores/LocationsStore";
import { useHistory } from "react-router-dom";
import getAddressFromLatLng from "../utils/getAddressFromLatLng";
import ToastsService from "../utils/ToastsService";
import { useOutClick } from "../utils/hooks/useOutClick.hook";
import { useScroll } from "../utils/hooks/useScroll.hook";
import PlacesAutocomplete from "react-places-autocomplete";
import { HOME_PAGE_URL, GOOGLE_API_KEY, PROD } from "../config";
import StorageService from "../services/StorageService";
import getCoordsFromAddress from "../utils/getCoordsFromAddress";

import { GoogleApiWrapper } from "google-maps-react";
import ReactGA from "react-ga";

const googleListMarker = require("../assets/images/autocomplete-icons.png");
const poweredByGoogle = require("../assets/images/powered_by_google_on_white.png");

const Header = observer(() => {
  const { getLocationsList, locations } = locationsStore;
  const [value, setValue] = useState("");
  const [isOpen, setOpen] = useState(false);
  let history = useHistory();
  const ref = React.createRef();

  const onChange = (value) => {
    setValue(value);
  };

  useOutClick(ref, () => {
    setOpen(false);
  });

  useScroll(() => {
    setOpen(false);
  });

  const onClear = () => {
    setValue("");
  };

  const onSelect = (value) => {
    setValue(value);
    onSearch(value.trim(), true);
  };

  const onSearch = async (value) => {
    if(PROD) { // for redirect to YEXT team side
      window.location.href = `https://locations.localocker.com/?q=${value}`;
    }

    const addressInfo = await getCoordsFromAddress(value);
    if (
      addressInfo?.data?.results[0]?.types &&
      addressInfo.data.results[0].types.some(
        (type) =>
          type === "street_address" ||
          type === "geocode" ||
          type === "postal_code" ||
          type === "premise" ||
          type === "intersection" ||
          type === "route"
      )
    ) {
      await getLocationsList(value, true);
      locations.length && onClear();
      StorageService.setItem("searchPath", value);
      locationsStore.searchPath = value;
    } else {
      ToastsService.showWarning("Please enter the ZIP code or address.");
    }
    ReactGA.pageview("config", "UA-120299096-1", {
      results_form_address: "ADDRESS2",
      custom_map: { dimension2: "results-form-address" },
      "results-form-address": value,
    });
  };

  const showPosition = async (position) => {
    try {
      const response = await getAddressFromLatLng(
        position.coords.latitude,
        position.coords.longitude
      );
      const zip = response.data.results[0].address_components.find(
        (component) => component.types.some((type) => type === "postal_code")
      );
      let address = "";
      if (response.data.results[0]) {
        address = response.data.results[0].formatted_address;
      }
      setValue(address);
      if (zip && zip.long_name) {
        onSearch(zip.long_name);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, (error) => {
        if (error.message === "User denied Geolocation") {
          ToastsService.showWarning(
            "Geolocation is not enabled. Please enable to use this feature"
          );
        }
      });
    } else {
      ToastsService.showWarning(
        "Geolocation is not supported by this browser."
      );
    }
  };

  const openMenu = () => {
    setOpen(true);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const contactBlock = (className) => (
    <div className={`contact-us ${className}`} ref={ref}>
      <a href="tel:+1 (646) 713-0937" className="contact">
        <ViberContact alt="LocaLocker Viber Contact Icon" />
        <p>Call or Text (646) 713-0937</p>
      </a>
      <a href="email:reserve@localocker.com" className="contact">
        <EmailContact alt="LocaLocker Email Contact Icon" />
        <p>reserve@localocker.com</p>
      </a>
      <a href={`${HOME_PAGE_URL}faq/`} className="contact faq">
        <p>FAQ</p>
      </a>
    </div>
  );

  // return <></>;
  return (
    <div className="header">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          alignItems: "center",
        }}
      >
        <div className="sub-logo">
          {" "}
          <a href={HOME_PAGE_URL}>
            {" "}
            <SubLogo alt="Locker Sub Logo" />{" "}
          </a>{" "}
        </div>
        <div className="logo-mobile">
          <a href={HOME_PAGE_URL}>
            <Logo alt="Locker main logo" />
          </a>
        </div>
        <div className="search">
          <PlacesAutocomplete
            value={value}
            onChange={onChange}
            onSelect={onSelect}
            searchOptions={{
              componentRestrictions: { country: ["us"] },
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div style={{ position: "relative" }}>
                <div className="autocomplete-dropdown-container">
                  {/* {loading && <div>Loading...</div>} */}
                  {suggestions.map((suggestion, index) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <>
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>
                            <span className="main-text">
                              {suggestion.formattedSuggestion.mainText || ""}
                            </span>
                            <span className="secondary-text">
                              {` ${
                                suggestion.formattedSuggestion.secondaryText ||
                                ""
                              }`}
                            </span>
                          </span>
                          <div
                            className="suggestion-icon"
                            style={{
                              backgroundImage: `url(${googleListMarker})`,
                            }}
                          />
                        </div>
                        {index === suggestions.length - 1 && (
                          <div
                            style={{
                              height: "20px",
                              backgroundColor: "white",
                              position: "relative",
                            }}
                          >
                            <div
                              className="google-logo"
                              style={{ background: `url(${poweredByGoogle})` }}
                            />
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          <div onClick={onGetCurrentLocation} className="navigation">
            <NavigatorIcon />
          </div>
        </div>
      </div>
      {contactBlock("desktop")}
      <div className="menu-toggle">
        {isOpen ? (
          <Close className="burger-menu" onClick={closeMenu} />
        ) : (
          <BurgerMenu className="burger-menu" onClick={openMenu} />
        )}
        {isOpen && contactBlock("mobile")}
      </div>
    </div>
  );
});

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
})(Header);
