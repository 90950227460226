import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={20}
            height={18}
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M18.171 6.047l-5.132-.738-2.32-4.676A1.11 1.11 0 009.698 0c-.387 0-.774.21-.985.633l-2.32 4.676-5.133.738c-.914.14-1.3 1.266-.633 1.933l3.727 3.622-.879 5.097c-.14.738.457 1.301 1.125 1.301.176 0 .352-.035.527-.105l4.57-2.426 4.606 2.426c.176.07.352.105.527.105.668 0 1.231-.563 1.126-1.3l-.88-5.098 3.727-3.622c.668-.668.281-1.793-.633-1.933zm-4.289 4.324l-.633.633.141.879.703 3.973-3.586-1.864-.808-.422.035-11.144 1.793 3.62.387.81.879.105 4.007.598-2.918 2.812z"
                fill="#00173C"
            />
        </svg>
    )
}

export default SvgComponent
