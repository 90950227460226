import React from 'react';
import propTypes from 'prop-types';

export const Button = ({ text, onClick, addClass, type, disabled, isLoading, style }) => (
  <div className={`button ${addClass || ''}`.trim()}>
    <button type={type} onClick={onClick} disabled={disabled} style={style}>
      {isLoading && <div class="lds-ring"><div></div><div></div><div></div><div></div></div>}
      {!isLoading && text}
    </button>
  </div>
);

Button.propTypes = {
  text: propTypes.string.isRequired,
  addClass: propTypes.string,
  onClick: propTypes.func,
  type: propTypes.string
};
