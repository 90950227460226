import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={16}
            height={15}
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                opacity={0.45}
                d="M8 .11C3.986.11.734 3.39.734 7.374A7.264 7.264 0 008 14.641c3.984 0 7.266-3.252 7.266-7.266C15.266 3.391 11.984.109 8 .109zm0 13.124a5.84 5.84 0 01-5.86-5.859A5.858 5.858 0 018 1.515c3.223 0 5.86 2.637 5.86 5.86A5.858 5.858 0 018 13.235zm0-9.902a1.22 1.22 0 00-1.23 1.23c0 .704.527 1.231 1.23 1.231a1.22 1.22 0 001.23-1.23c0-.674-.556-1.231-1.23-1.231zm1.64 7.441v-.703a.378.378 0 00-.35-.351h-.352v-2.93a.378.378 0 00-.352-.351H6.71a.356.356 0 00-.352.351v.703c0 .205.147.352.352.352h.351v1.875h-.351a.356.356 0 00-.352.351v.703c0 .206.147.352.352.352h2.578a.356.356 0 00.352-.352z"
                fill="#00173C"
            />
        </svg>
    )
}

export default SvgComponent
