import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={30}
            height={30}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M16.029.017h-.135a.869.869 0 01.135 0zM20.851 19.046c-.3-.267-1.767-1.565-2.053-1.764-.286-.2-.5-.325-.824-.046-.324.279-1.2.875-1.46 1.043-.26.168-.475.147-.772-.117a9.79 9.79 0 01-2.31-2.463c-.563-.9-.991-1.877-1.272-2.9-.1-.4.128-.533.346-.657.2-.111.453-.3.682-.456.189-.134.357-.295.5-.478a.642.642 0 00.135-.606c-.04-.195-.267-2.07-.369-2.836-.102-.766-.417-.727-.62-.782-.203-.055-.42-.15-.652-.213a1.327 1.327 0 00-1.037.174 4 4 0 00-1.992 2.53 6.89 6.89 0 00.434 3.926c.111.279 1.345 4.454 4.478 6.788 3.133 2.334 3.259 1.88 3.907 1.994a3.6 3.6 0 002.775-.986 2.91 2.91 0 00.652-1.574c-.047-.165-.255-.312-.552-.577h.004z"
                fill="#E62222"
            />
            <path
                d="M15.143 26.672a12.3 12.3 0 01-6.757-2.013l-4.715 1.5 1.53-4.522a12.082 12.082 0 01-2.336-7.141A12.242 12.242 0 0115.142 2.31 12.245 12.245 0 0127.42 14.496a12.243 12.243 0 01-12.275 12.178v-.002zm0-26.672A14.551 14.551 0 00.537 14.492a14.31 14.31 0 002.1 7.478L0 29.748l8.083-2.568a14.656 14.656 0 007.061 1.8 14.546 14.546 0 0014.6-14.485A14.549 14.549 0 0015.144.001z"
                fill="#E62222"
            />
        </svg>
    )
}

export default SvgComponent
