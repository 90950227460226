import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import customMapStyle from '../utils/customMapStyle';
import { CustomMarkerIcon } from '../utils/CustomMarker';
import MarkerIcon from '../assets/icons/map-marker.svg';
import WhiteMarkerIcon from '../assets/icons/map-marker-white.svg';
import { observer } from 'mobx-react';
import locationsStore from '../stores/LocationsStore';
import getCoordsFromAddress from '../utils/getCoordsFromAddress';
import getGeometricCenter from '../utils/getGeometricCenter';
import { GOOGLE_API_KEY } from '../config';

const ConnectedInfoWindow = observer(props => {
  const { activeMarker } = locationsStore;
  const onInfoWindowOpen = (props, e) => {
    const div = (
      <div
        style={{ cursor: 'pointer' }}
        onClick={props.getLocation(activeMarker?.title, activeMarker?.id)}
        // onTouch={props.getLocation(activeMarker?.title, activeMarker?.id)}
        className="location-name"
      >
        {(activeMarker && activeMarker.title) || 'Location'}
      </div>
    );
    ReactDOM.render(React.Children.only(div), document.getElementById('iwc'));
  };

  return (
    <InfoWindow
      position={activeMarker && activeMarker.position}
      visible={true}
      maxWidth={165}
      onOpen={event => {
        onInfoWindowOpen(props, event);
      }}
      {...props}
    >
      <div id="iwc" />
    </InfoWindow>
  );
});

const GoogleMap = ({
  google,
  location,
  locations,
  markerList,
  hoverEffect,
  setMarkerList,
  onInitCurrentPosition,
  getLocation,
  withoutZoom,
}) => {
  const [center, setCenter] = useState(undefined);

  const geocoder = new google.maps.Geocoder();

  const _mapLoaded = (_, map) => {
    map.setOptions({
      styles: customMapStyle,
    });
  };

  useEffect(() => {
    onInitCurrentPosition && getCurrentZip();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (markerList) {
      const coordsArray = markerList.map(marker => [marker.position.lat, marker.position.lng]);
      if (coordsArray.length) {
        const geometricCenter = getGeometricCenter(coordsArray);
        setCenter({ lat: geometricCenter[0], lng: geometricCenter[1] });
      }
    }
  }, [markerList]);

  useEffect(() => {
    if (locations && locations.length) {
      getLocationsWithCoords();
    }

    async function setCenterMap() {
      let position = {
        lat: 40.717475,
        lng: -73.982618,
      };
      try {
        const response = await getCoordsFromPlaceID(location.google_place_id);
        if (response) {
          position = response;
        }
      } catch (err) {
        const res = await getCoordsFromAddress(location.address);
        if (res.data.results[0]) {
          position = {
            lat: res.data.results[0].geometry.location.lat,
            lng: res.data.results[0].geometry.location.lng,
          };
        }
      }
      setCenter(position);
    }

    location && setCenterMap();
    // eslint-disable-next-line
  }, [locations, location]);

  const getCoordsFromPlaceID = async placeId =>
    await new Promise((resolve, reject) => {
      geocoder.geocode({ placeId: placeId }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            resolve({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            });
          } else {
            console.warn('No results found');
            reject();
          }
        } else {
          console.warn('Geocoder failed due to: ' + status);
          reject();
        }
      });
    });

  const getLocationsWithCoords = async () => {
    const convertLocation = async location => {
      let position = {
        lat: 40.717475,
        lng: -73.982618,
      };
      try {
        position = await getCoordsFromPlaceID(location.google_place_id);
      } catch (error) {
        const res = await getCoordsFromAddress(location.address);
        if (res.data.results[0]) {
          position = {
            lat: res.data.results[0].geometry.location.lat,
            lng: res.data.results[0].geometry.location.lng,
          };
        }
      }
      return {
        position,
        active: false,
        title: location.title,
        key: location.google_place_id,
        id: location.id,
      };
    };
    const locationsWithCoords = locations.map(convertLocation);
    const result = await Promise.all(locationsWithCoords);
    setMarkerList(result);
  };

  const getCurrentZip = (latitude, longitude) => {
    const latlng = new google.maps.LatLng(latitude, longitude);
    setCenter({
      // set center for markers map
      lat: 40.717475,
      lng: -73.982618,
    });

    geocoder.geocode({ latLng: latlng }, function(results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          for (let j = 0; j < results[0].address_components.length; j++) {
            if (results[0].address_components[j].types[0] === 'postal_code')
              onInitCurrentPosition(results[0].address_components[j].short_name);
          }
        }
      } else {
        console.warn('Geocoder failed due to: ' + status);
      }
    });
  };

  const handleMouseEvent = (isOut, placeId) => () => {
    isOut ? hoverEffect(placeId) : hoverEffect();
  };

  return (
    <div className="google-map">
      <Map
        google={google}
        onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
        center={center}
        fullscreenControl={false}
        streetViewControl={false}
        panControl={false}
        rotateControl={false}
        zoomControl={!withoutZoom}
        scaleControl={false}
        mapTypeControl={false}
        minZoom={10}
        maxZoom={15}
        zoom={13.5}
      >
        {location ? (
          <Marker
            position={center}
            icon={{
              url: MarkerIcon,
            }}
          />
        ) : (
          markerList &&
          markerList.length &&
          markerList
            .filter(e => e.position)
            .map((location, index) => (
              <Marker
                key={index}
                onClick={getLocation(location?.title, location?.id)}
                position={location.position}
                icon={{
                  url: WhiteMarkerIcon,
                }}
                onMouseover={() => {
                  handleMouseEvent(true, location.id)();
                }}
              />
            ))
        )}
        <ConnectedInfoWindow getLocation={getLocation} />
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
})(GoogleMap);
