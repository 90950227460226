import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={20}
            height={19}
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M18.146 6.047l-5.133-.738-2.32-4.676c-.387-.809-1.582-.844-2.004 0L6.404 5.309l-5.168.738C.322 6.187-.03 7.312.638 7.98l3.692 3.621-.88 5.098c-.14.914.845 1.617 1.653 1.195L9.71 15.47l4.57 2.426c.809.421 1.793-.282 1.652-1.196l-.879-5.098 3.692-3.62c.668-.669.316-1.794-.598-1.934zm-4.922 4.957l.844 4.851-4.36-2.285-4.394 2.285.844-4.851-3.55-3.445 4.886-.704 2.215-4.43 2.18 4.43 4.886.704-3.55 3.445z"
                fill="#00173C"
            />
        </svg>
    )
}

export default SvgComponent
