import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={14}
            height={10}
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M5.393 9.797a.675.675 0 00.939 0l7.465-7.465a.675.675 0 000-.94L12.883.48a.644.644 0 00-.914 0L5.875 6.572 3.005 3.73a.644.644 0 00-.913 0l-.914.914a.675.675 0 000 .939l4.215 4.215z"
                fill="#00173C"
            />
        </svg>
    )
}

export default SvgComponent
