class StorageService {
  getItem = key => {
    try {
      const itemFromStorage = localStorage.getItem(key);
      if (!itemFromStorage) return null;
      return JSON.parse(itemFromStorage);
    } catch (e) {
      return null;
    }
  };

  setItem = (key, value) => {
    try {
      const stringifyedItem = JSON.stringify(value);
      localStorage.setItem(key, stringifyedItem);
      return value;
    } catch (e) {
      return null;
    }
  };
}
export default new StorageService();
