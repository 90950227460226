import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle opacity={0.1} cx={20} cy={20} r={20} fill="#1CD67D" />
      <path
        d="M18.394 24.797a.674.674 0 00.939 0l7.465-7.465a.674.674 0 000-.94l-.914-.913a.644.644 0 00-.914 0l-6.094 6.093-2.87-2.843a.644.644 0 00-.913 0l-.914.914a.674.674 0 000 .939l4.215 4.215z"
        fill="#1CD67D"
      />
    </svg>
  )
}

export default SvgComponent
