import React from 'react';
import { Table } from 'antd';
import Rating from './Rating';

import moment from 'moment';

export default ({ reviews, total, totalRating }) => {
  const customerReview = (review, rating) => (
    <div>
      <Rating rating={rating} />
      <p>{review}</p>
    </div>
  );

  const tableHeadRaiting = (
    <div className="table-head-raiting">
      <Rating rating={totalRating} label={`${total} reviews`} />
    </div>
  );

  const tableCell = (name, data, rating) => (
    <div className="table-cell">
      <h4>{name}</h4>
      <div className="rating-mobile">
        <h4>{name}</h4>
        <Rating rating={rating} />
      </div>
      <p>{new Date(data * 1000).toLocaleString().split(', ')[0]}</p>
    </div>
  );

  const sortDate = reviews =>
    reviews.sort(
      (a, b) =>
        new moment(b.time * 1000).format('YYYYMMDD') - new moment(a.time * 1000).format('YYYYMMDD')
    );

  const createTableRow = () =>
    sortDate(reviews).map((review, index) => ({
      key: index,
      name: tableCell(review.author_name, review.time, review.rating),
      review: customerReview(review.text, review.rating)
    }));

  const columns = [
    {
      title: 'Customer reviews',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: tableHeadRaiting,
      dataIndex: 'review',
      key: 'review'
    }
  ];

  return (
    <div className="customer-rewiew-table">
      <Table dataSource={createTableRow()} columns={columns} pagination={false} />
    </div>
  );
};
