import React from 'react';
import { paymentDescriptions } from '../utils/paymentDescriptions';
import { number } from 'prop-types';
import parseNumber from '../utils/parseNumber';

export default ({ price, prepay, onClick, isSelected, centerCard, duration, threeMonthMinimumRequired }) => {
  const title = duration === 1 ? 'Monthly Subscription' : `${duration} Month Commitment`;
  const fixedNumber = parseNumber(price);
  return (
    <div
      className={`payment-card ${centerCard ? 'center-card' : ''} ${
        isSelected ? 'selected' : ''
      }`.trim()}
      onClick={onClick}
    >
      <div className="card-title">{title}</div>
      <div className="price">
        ${fixedNumber}
        <span>per month</span>
      </div>
      {prepay && <div className="prepay">{prepay}</div>}
      <div className="descripton">
        <i>
          <sup>*</sup> {paymentDescriptions[threeMonthMinimumRequired ? 0 : duration]}
        </i>
      </div>
    </div>
  );
};
