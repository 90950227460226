import React from 'react';
import { StarFull } from '../assets/icons';
import { StarBlank } from '../assets/icons';
import { StarHalf } from '../assets/icons';
import Rating from 'react-rating';

export default ({ rating, label }) => {
    return (
        <div className="rating-container">
            <Rating readonly initialRating={rating}
                emptySymbol={<StarBlank alt='Blank Star' />}
                fullSymbol={<StarFull alt='Full Star' />}
                placeholderSymbol={<StarHalf alt='Half Star' />}
            />
            {label && <div className="rating-label">{rating === '-' ? 'No reviews' : label}</div>}
        </div>
    );
};
