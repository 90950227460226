import React from "react";
import { CardIcon } from "../assets/icons";
import { ACHIcon } from "../assets/icons";
import { Input } from "./Input";
import { observer } from "mobx-react";
import AchPlaid from "./AchPlaid";

export default observer(
  ({
    setPaymentOptions,
    paymentOptions,
    achFields,
    creditFields,
    cardOnChange,
  }) => {
    const selectOption = (num) => () => {
      setPaymentOptions(num);
    };
    return (
      <div className="payment-info">
        <div className="payment-title">Payment Information</div>
        <div className="payment-select-box">
          <div
            className={`p-select-option  ${
              paymentOptions === 1 ? "selected" : ""
            }`}
            onClick={selectOption(1)}
          >
            <ACHIcon alt="ACH Icon" />
            <span className="option-label">ACH</span>
          </div>
          <div
            className={`p-select-option ${
              paymentOptions === 0 ? "selected" : ""
            }`}
            onClick={selectOption(0)}
          >
            <CardIcon alt="Card Icon" />
            <span className="option-label">Credit Card</span>
          </div>
        </div>
        <div className="payment-label">
          {/* Please fill in for adding a new card */}
        </div>
        <div className="payment-container">
          {paymentOptions === 0 ? (
            <div className="credit-card-form">
              <div className="two-inputs">
                <Input
                  label="First Name"
                  name="firstName"
                  value={creditFields.firstName.value}
                  onInput={creditFields.firstName.onChange}
                  error={creditFields.firstName.error}
                />
                <Input
                  label="Last Name"
                  name="lastName"
                  value={creditFields.lastName.value}
                  onInput={creditFields.lastName.onChange}
                  error={creditFields.lastName.error}
                />
              </div>
              <Input
                label="Card Number"
                name="cardNumber"
                placeholder="xxxx  xxxx  xxxx  xxxx"
                isCardNumber
                onInput={cardOnChange}
                error={creditFields.cardNumber.error}
              />
              <div className="two-inputs">
                <Input
                  label="Expiration Date"
                  name="cardExpiry"
                  placeholder="MM / YY"
                  isExpiry
                  onInput={cardOnChange}
                  error={creditFields.cardExpiry.error}
                />
                <Input
                  label="CVC / CVV"
                  name="cardCvc"
                  isCVC
                  placeholder=""
                  onInput={cardOnChange}
                  error={creditFields.cardCvc.error}
                />
              </div>
            </div>
          ) : (
            <AchPlaid />
          )}
        </div>
      </div>
    );
  }
);
