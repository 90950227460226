import React, { useEffect } from 'react';
import { Input } from './Input';
import { Button } from './Button';
import InputSelect from './InputSelect';
import { observer } from 'mobx-react';
import locationsStore from '../stores/LocationsStore';
import { aboutUsOptions } from '../utils/selectOptions';

export default observer(({ onClose }) => {
  const { waitListData: data, onSubmit, modalSucces } = locationsStore;
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const scrollToBottom = () => {
    document.querySelector('.modal-mask').scrollTo(0, document.body.scrollHeight);
  };

  return (
    <div
      className="modal-mask"
      onClick={() => {
        onClose();
      }}
    >
      <div
        className="wait-list"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <span className="close-btn" onClick={onClose}>
          &times;
        </span>

        <div className="modal-title">
          <h3>Join Waitlist</h3>
        </div>
        <div className="modal-info">
          <p>
            {!modalSucces
              ? 'Please provide your personal information. We will notify you as soon as the unit you are interested in is available'
              : 'Thank you! We will get in touch soon!'}
          </p>
        </div>
        <div className="modal-form">
          {!modalSucces && (
            <>
              <div className="name-inputs">
                <Input
                  label="First name"
                  name="firstName"
                  onInput={data.firstName.onChange}
                  error={data.firstName.error}
                  addClass="first-name"
                />
                <Input
                  label="Last name"
                  name="lastName"
                  onInput={data.lastName.onChange}
                  error={data.lastName.error}
                  addClass="last-name"
                />
              </div>
              <div className="info-inputs">
                <Input
                  label="Email"
                  name="email"
                  onInput={data.email.onChange}
                  error={data.email.error}
                />
                <Input
                  label="Phone"
                  type="tel"
                  name="phone"
                  onInput={data.phone.onChange}
                  error={data.phone.error}
                />
              </div>
              <div>
                <InputSelect
                  optionsList={aboutUsOptions}
                  name="aboutUs"
                  handleChange={data.aboutUs.onChange}
                  error={data.aboutUs.error}
                  label="How did you hear about us?"
                  scrollTo={scrollToBottom}
                />
              </div>
            </>
          )}

          <div className="submit-btn">
            <Button
              text={!modalSucces ? 'Submit' : 'Ok'}
              addClass="submit"
              onClick={!modalSucces ? onSubmit : onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
