import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Button } from '../Button';
import Spinner from '../Spinner';
import bookingStore from '../../stores/bookingStores/BookingStore';

export default observer(({ prevStep, nextStep }) => {
    const { docuSignUrl, isFetching, setHistory } = bookingStore.docuSignStore;
    const history = useHistory();

    const eventHandler = event => {
        const { data } = event;
        if (!data || !data.status) return;

        if (data.status === 'signing_complete') {
            nextStep();
        } else {
            bookingStore.setStep(1);
        }
    };

    useEffect(() => {
        window.addEventListener('message', eventHandler);
        setHistory(history);
        bookingStore.billingStore.paymentSuccess = false;
        return () => {
            window.removeEventListener('message', eventHandler);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div className="third-step">
            <h4 className="docusign-title">Sign an Agreement</h4>
            <div className="docusign-container">
                {isFetching ? (
                    <Spinner />
                ) : (
                        <iframe
                            src={docuSignUrl}
                            name="docuSign"
                            title="Docu sign"
                            allowFullScreen={true}
                        ></iframe>
                    )}
            </div>

            {/* <div className="navigation-btns">
        <Button text="Back" addClass="booking-navigation-btn back" onClick={prevStep} />
      </div> */}
        </div>
    );
});
