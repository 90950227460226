import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={35}
      height={57}
      viewBox="0 0 35 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.05 46.599V.112H.135v56.207h34.29v-9.72H10.05zM18.422.112v6.03h9.884v21.272h6.12V.114L18.423.112z"
        fill="#00173C"
      />
    </svg>
  )
}

export default SvgComponent
