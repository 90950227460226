export const formatTelephone = phone => {
  let result = phone;
  if (!result) return '';
  if (result.indexOf('+') === 0) {
    // is twilio
    result = result
      .slice(2)
      .split('')
      .map((digit, index) => {
        if (index === 0) {
          return `(${digit}`;
        }
        if (index === 2) {
          return `${digit}) `;
        }
        if (index === 5) {
          return `${digit} -`;
        }
        return digit;
      })
      .join('');
    return result;
  }
  const isCode = /\([0-9]{3}\)/.test(result);
  if (isCode) {
    return result;
  } else {
    return '(046)' + result;
  }
};
