import React, { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";

import { Calendar } from "../assets/icons";
import { Info } from "../assets/icons";

class DateInput extends React.PureComponent {
  render() {
    return <input {...this.props} readOnly />;
  }
}

export default ({ label, date, setDate }) => {
  const [displayInfo, showInfo] = useState(false);
  const [value, setValue] = useState(date);
  const dateNow = new Date().getHours() >= 19
      ? new Date(moment().add(1, "day"))
      : new Date();
  // console.log('dateNow', new Date(dateNow))
  const currentDate = useMemo(() => value < dateNow ? dateNow : value, [value]) 
  
  // console.log('currentDate', currentDate)
  // console.log('nextMonthDays', nextMonthDays, dateNow.getDate() + nextMonthDays);
  const maxDate = new Date(moment(new Date(dateNow)).add(14, "day"));
  // console.log('maxDate', new Date(maxDate))

  const handleChange = (date) => {
    setValue(date);
  };

  // useEffect(() => {
  //   // if(currentDate !== date){
  //     setDate(currentDate);
  //   // }
  // },[])

  useEffect(() => {
    setDate(currentDate);
  },[currentDate, value])

  return (
    <div className="data-picker-container">
      {label && (
        <div className="label">
          <label>{label}</label>{" "}
          <Info
            onMouseOver={() => showInfo(true)}
            onMouseOut={() => showInfo(false)}
            className="info-icon"
          />
          {displayInfo && (
            <div className="info">
              Move-in date must be within 1 month of today
            </div>
          )}
        </div>
      )}
      <Calendar className="calendar-icon" />
      <DatePicker
        selected={currentDate}
        onChange={handleChange}
        customInput={<DateInput />}
        className="date-picker"
        minDate={dateNow}
        maxDate={maxDate}
      />
    </div>
  );
};
