import React from 'react';

export default str =>
  str.split(' ').map(el => {
    if (el.match(/@/i) === null) {
      return <span>{el} </span>;
    } else {
      return <a>{el} </a>;
    }
  });
