import React from 'react';
import classNames from 'classnames';
import { BASE_URL } from '../config';

const placeholder = require('../assets/images/placeholder.png');

export default ({ preferMobile, url, className, isImage, onClick, mobileUrl }) => {
  const alt = url
    ? url.split('/')[url.split('/').length - 1].split('.')[0].split('-').join(' ')
    : '*';
  if (isImage) {
    const imageClassName = classNames('image-view-img', className);
    const currentImage =
      (preferMobile && mobileUrl) || (window.innerWidth < 768 && mobileUrl) ? mobileUrl : url;
    const ImageUrl = currentImage ? `${BASE_URL}${currentImage}` : placeholder;

    return (
      <img
        className={imageClassName}
        src={ImageUrl}
        alt={alt}
        onClick={onClick}
        style={{ objectFit: 'cover' }}
      />
    );
  }
  const divClassName = classNames('image-view', className);
  const backgroundImageUrl = url ? `url(${BASE_URL}${url})` : undefined;

  return (
    <div
      className={divClassName}
      style={{ backgroundImage: backgroundImageUrl }}
      onClick={onClick}
    />
  );
};
