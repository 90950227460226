import React, { useState } from 'react';
import { NavigatorIcon } from '../assets/icons';
import ImageView from './ImageView';
import { observer } from 'mobx-react';
import locationsStore from '../stores/LocationsStore';
import { formatTelephone } from '../utils/fomatTelephone';
import LazyLoad from 'react-lazyload';

export default observer(({ imgUrl, title, address, telephone, getLocation, imgMobile, id }) => {
    const { activeMarker, hoverEffect } = locationsStore;
    const [mobileClick, setMobileClick] = useState(false);
    const handleMouseEvent = (isOut) => () => {
        isOut ? hoverEffect(id) : hoverEffect();
    };

    const onClickHandler = (event) => {
        event.persist();

        getLocation(event);
    };

    const onTouchHandler = (event) => {
        event.persist();
        mobileClick && getLocation(event);
    };

    return (
        <div
            className={`location-card ${activeMarker && id === activeMarker.id ? 'hovered' : ''}`}
            onClick={onClickHandler}
            onMouseOver={handleMouseEvent(true)}
            onMouseLeave={handleMouseEvent(false)}
            onTouchStart={() => setMobileClick(true)}
            onTouchMove={() => setMobileClick(false)}
            onTouchEnd={onTouchHandler}
        >
            <LazyLoad height={96}>
                <ImageView className="card-img" url={imgUrl} mobileUrl={imgMobile} isImage preferMobile />
            </LazyLoad>
            <div className="card-info">
                <div className="card-title">{title}</div>
                <div className="location-coord">
                    <NavigatorIcon /> {address}
                </div>
                <div className="card-number">
                    <a href={`tel: ${telephone}`} onClick={(event) => event.stopPropagation()}>
                        {formatTelephone(telephone)}
                    </a>
                </div>
            </div>
        </div>
    );
});
