import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import LocationsPage from '../pages/LocationsPage';
import SingleLocationPage from '../pages/SingleLocationPage';
import BookingPage from '../pages/BookingPage';
import FinalBookingPage from '../pages/FinalBookingPage';
import PaymentPage from '../pages/PaymentPage';
import ScrollToTop from '../components/ScrollToTop';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RedirectPage from '../pages/RedirectPage';

export default () => (
    <Router>
        <ToastContainer />
        <ScrollToTop />
        <Switch>
            <Route
                exact
                path={['/', '/:id', '/:name/:id']}
                render={() => {
                    const pathname = window.location.pathname;
                    if (pathname === '/' || pathname.match('/locations')) {
                        return <LocationsPage />;
                    }
                    if (pathname.match('/booking-success') || pathname.match('/booking-error')) {
                        return <FinalBookingPage />;
                    }
                    if (pathname.match('/booking')) {
                        return <BookingPage />;
                    }
                    if (pathname.match('/update-payment')) {
                        return <PaymentPage />;
                    }

                    if (Number(pathname.split('/')[2]) > -1 || Number(pathname.split('/')[1]) > -1) {
                        return <SingleLocationPage />;
                    }
                    return <RedirectPage />;
                }}
            />
            <Route exact path="*" render={RedirectPage} />
        </Switch>
    </Router>
);
