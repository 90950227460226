import React from "react";
import propTypes from "prop-types";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import PhoneInput from "react-phone-number-input/input";

export const Input = ({
  label,
  error,
  onInput,
  value,
  addClass,
  name,
  type,
  placeholder,
  subLabel,
  isCardNumber,
  isExpiry,
  isCVC,
  maxLength,
  tabIndex,
  borderColor,
  disabled,
  options,
}) => {
  const inputClass = `input ${addClass || ""} ${error ? "error" : ""}`.trim();
  const selectClass = `select ${addClass || ""} ${error ? "error" : ""}`.trim();

  const onPhoneHandler = (value) => {
    onInput({
      target: {
        value: value,
      },
    });
  };
  return (
    <div className={options ? selectClass : inputClass}>
      {label && (
        <label>
          {label.trim()}
          <p>{subLabel}</p>
        </label>
      )}
      {isCardNumber ? (
        <CardNumberElement options={{ placeholder }} onChange={onInput} />
      ) : isExpiry ? (
        <CardExpiryElement options={{ placeholder }} onChange={onInput} />
      ) : isCVC ? (
        <CardCvcElement options={{ placeholder }} onChange={onInput} />
      ) : name === "phone" ? (
        <PhoneInput
          country="US"
          value={value}
          onChange={onPhoneHandler}
          name={name}
          placeholder={placeholder}
          tabindex={tabIndex}
          maxLength={16}
        />
      ) : options ? (
        <select
          value={value}
          onChange={onInput}
          style={{ borderColor: borderColor }}
        >
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      ) : (
        <input
          maxLength={maxLength}
          onChange={onInput}
          value={value}
          name={name}
          type={type}
          style={{ borderColor: borderColor }}
          placeholder={placeholder}
          tabindex={tabIndex}
          autoCapitalize="none"
          disabled={disabled}
        />
      )}
      {error && <span>{error.trim()}</span>}
    </div>
  );
};

Input.propTypes = {
  label: propTypes.string,
  error: propTypes.string,
  onInput: propTypes.func,
  value: propTypes.string,
  addClass: propTypes.string,
  name: propTypes.string,
  type: propTypes.string,
};
