import React, { useState } from 'react';
import logo from '../assets/icons/locker-main-logo.svg';
import { HOME_PAGE_URL } from '../config';

export default () => {
    // const [dialog, toggleDialog] = useState(false);

    return (
        <div className="sidebar">
            <div className="sidebar-logo">
                <a href={HOME_PAGE_URL}>
                    {/* <Logo /> */}
                    <img src={logo} alt="Locker Main Logo" />
                </a>
            </div>
            {/* <div className="sidebar-dialog">
        <Dialog
          onMouseOver={() => {
            toggleDialog(true);
          }}
        />
        <div className="dialog-window" style={{ display: dialog ? 'block' : 'none' }}>
          <div className="arrow-left" />
          <Close
            onClick={() => {
              toggleDialog(false);
            }}
          />
          <span>Questions?</span> Our team has the answer you’re looking for.
        </div>
      </div> */}
        </div>
    );
};
