import { action, observable, decorate } from "mobx";

export default class Field {
    value;
    error;
    name = "";
    required = false;

    constructor(name, required, lable='') {
        this.value = "";
        this.error = "";
        this.name = name;
        this.required = required;
        this.label = lable || this.value
    }

    onChange = (event) => {
        const value = event.target ? event.target.value : event;
        this.value = value;
    };
}

decorate(Field, {
    value: observable,
    error: observable,
    label: observable,
    onChange: action,
})
