import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.07 0H2.93A2.933 2.933 0 000 2.93v19.14A2.933 2.933 0 002.93 25h19.14A2.933 2.933 0 0025 22.07V2.93A2.933 2.933 0 0022.07 0zm.977 22.07a.979.979 0 01-.977.977h-5.761v-7.129h3.126l.364-3.027h-3.49V9.619a1.432 1.432 0 011.465-1.465h2.2V5.322c-.73-.104-1.464-.17-2.2-.2a4.521 4.521 0 00-3.132 1.318 4.446 4.446 0 00-1.36 3.183v3.263h-3.175v3.027h3.174v7.129H2.93a.978.978 0 01-.977-.977V2.93a.978.978 0 01.977-.977h19.14a.978.978 0 01.977.977v19.14z"
        fill="#E62222"
      />
    </svg>
  )
}

export default SvgComponent
