import React, { useEffect } from 'react';
import LocationCard from '../components/LocationCard';
import GoogleMap from '../components/GoogleMap';
import BackButton from '../components/BackButton';
import { observer } from 'mobx-react';
import LocationsStore from '../stores/LocationsStore.js';
import Spinner from '../components/Spinner';
import { useHistory } from 'react-router-dom';
import { UnavailableLocations } from '../assets/icons';
import { parseUrl } from '../utils/helper';
import Template from '../components/Template';
import { HOME_PAGE_URL, PROD } from '../config';
import StorageService from '../services/StorageService';
import ReactGA from 'react-ga';

export default observer(() => {
    const {
        getLocationsList,
        isFetching,
        locations,
        setMarkerList,
        hoverEffect,
        markerList,
        isInitial,
        isSearching,
        setSearchPath,
    } = LocationsStore;
    let history = useHistory();

    useEffect(() => {
        const { pathname } = history.location;
        if (PROD) {
            ReactGA.event({
                category: 'pageview',
                action: window.location.pathname,
                name: 'pageview',
            });
        }
        const params = parseUrl(history.location.pathname);
        const { param } = params;
        const isLoc = param === 'locations' ? '' : param;
        const searchPath = pathname.split('/')[2];
        setSearchPath(searchPath);
        getLocationsList(isLoc);
    }, []);

    const getLocationHandler = (name, id) => () => {
        name ? history.push(`/${name}/${id}`.replace(/\s/g, '')) : history.push(`/${id}`);
    };

    const getLocationListWithZip = zip => {
        !locations && getLocationsList(zip);
    };

    const backToHome = () => {
        window.location.href = HOME_PAGE_URL;
    };
    if (isInitial || isFetching) return <Spinner addClass="locations-spinner" />;

    return (
        <Template>
            <div className="locations-page">
                <BackButton text="Back" onClick={backToHome} />
                <div className="locations-list">
                    <div className="list-title">
                        <h3>Locations found</h3>
                    </div>
                    {!isSearching ? (
                        locations &&
                        locations.map(location => (
                            <LocationCard
                                key={location.id}
                                id={location.id}
                                title={location.title}
                                imgUrl={location.photo}
                                imgMobile={location.mobile_photo}
                                address={location.address}
                                telephone={location.telephone}
                                getLocation={getLocationHandler(location.title, location.id)}
                                placeId={location.google_place_id}
                            />
                        ))
                    ) : (
                            <Spinner addClass="search-spinner" />
                        )}
                    {!isSearching && !isInitial && !isFetching && !locations.length && (
                        <div className="not-found">
                            <UnavailableLocations className="not-found-icon" />
                            <div className="info-text">Sorry there are no locations currently in your area</div>
                        </div>
                    )}
                </div>
                <div className="location-map">
                    <GoogleMap
                        onInitCurrentPosition={getLocationListWithZip}
                        locations={locations}
                        setMarkerList={setMarkerList}
                        hoverEffect={hoverEffect}
                        markerList={markerList}
                        getLocation={getLocationHandler}
                    />
                </div>
            </div>
        </Template>
    );
});
