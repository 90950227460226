import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={70}
            height={167}
            viewBox="0 0 70 167"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M20.232 94.864V0H0v114.7h69.975V94.864H20.232zM37.314 0v12.305h20.17v43.411h12.487V0H37.314zM1.17 126.375h5.389v10.87h4.864v4.34H1.17v-15.21zM12.109 136.182v-.17c0-3.7 2.684-5.893 6.1-5.893 3.39 0 6.055 2.106 6.055 5.744v.17c0 3.744-2.7 5.85-6.075 5.85s-6.08-1.936-6.08-5.701zm7.468-.106v-.17c0-1.511-.384-2.234-1.372-2.234-.95 0-1.413.659-1.413 2.17v.17c0 1.6.4 2.34 1.413 2.34.968 0 1.372-.745 1.372-2.276zM24.863 136.182v-.17a5.53 5.53 0 015.894-5.893c2.765 0 5.308 1.234 5.47 4.914h-4.3c-.1-.66-.222-1.191-1.049-1.191-.949 0-1.312.617-1.312 2.085v.17c0 1.532.323 2.3 1.372 2.3.747 0 1.13-.489 1.211-1.382h4.138c-.061 3-1.837 4.871-5.672 4.871-3.249-.003-5.752-1.811-5.752-5.704zM37.072 138.501c0-2.638 1.837-3.638 5.773-3.638h.747v-.063c0-.7-.04-1.494-.95-1.494-.745 0-.887.728-.927.967h-4.28c.142-2.644 2.18-4.154 5.49-4.154 3.372 0 5.229 1.446 5.229 4.318v7.153h-4.441v-1.26a3.199 3.199 0 01-3.048 1.552c-1.877.001-3.593-.977-3.593-3.381zm6.52-.822v-.457h-.893c-1.378 0-1.607.269-1.607.941 0 .484.408.78 1.072.78a1.297 1.297 0 001.308-.747c.075-.163.116-.339.12-.518v.001zM50.11 125.779h4.582v15.806H50.11v-15.806zM1.17 150.919h5.389v10.87h4.864v4.34H1.17v-15.21zM12.109 160.725v-.17c0-3.7 2.684-5.893 6.1-5.893 3.39 0 6.055 2.106 6.055 5.744v.17c0 3.744-2.7 5.85-6.075 5.85s-6.08-1.936-6.08-5.701zm7.468-.107v-.17c0-1.511-.384-2.234-1.372-2.234-.95 0-1.413.66-1.413 2.17v.17c0 1.6.4 2.34 1.413 2.34.968.001 1.372-.744 1.372-2.276zM24.863 160.725v-.17a5.53 5.53 0 015.894-5.893c2.765 0 5.308 1.234 5.47 4.914h-4.3c-.1-.66-.222-1.191-1.049-1.191-.949 0-1.312.617-1.312 2.085v.17c0 1.532.323 2.3 1.372 2.3.747 0 1.13-.489 1.211-1.383h4.138c-.061 3-1.837 4.871-5.672 4.871-3.249-.002-5.752-1.81-5.752-5.703zM37.214 150.322h4.622v8.488l2.474-3.808h4.682l-3.322 4.914 3.544 6.212h-5.066l-2.312-4.063v4.063h-4.622v-15.806z"
                fill="#00173C"
            />
            <path
                d="M48.455 160.725v-.17a5.749 5.749 0 016.1-5.893c3.108 0 5.692 1.83 5.692 5.85v1.042h-7.145c.12 1.106.727 1.638 1.574 1.638.969 0 1.25-.532 1.293-.707h4.254c-.243 2.366-2.1 3.94-5.713 3.94-3.471.001-6.055-1.977-6.055-5.7zm7.367-1.425c-.02-.915-.384-1.6-1.271-1.6-.767 0-1.312.51-1.433 1.6h2.704zM61.694 155.003h4.623v2.319a3.206 3.206 0 013.37-2.447v4.531c-2.24-.17-3.37.6-3.37 1.872v4.85h-4.623v-11.125z"
                fill="#00173C"
            />
        </svg>
    )
}

export default SvgComponent
