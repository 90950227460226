import React, { useEffect } from "react";
import { Button } from "../Button";
import PlanCard from "../PlanCard";
import { observer } from "mobx-react";
import classNames from "classnames";
import bookingStore from "../../stores/bookingStores/BookingStore";
import { useHistory } from "react-router-dom";
import UploadInsuranceModal from '../UploadInsuranceModal';
import FileIcon from '../../assets/icons/FileIcon';
import BrochurePDF from '../../assets/files/Brochure.pdf';

export default observer(({ nextStep, prevStep }) => {
  const { 
    protectionPlans, 
    selectedPlan, 
    selectPlan, 
    setHistory, 
    showModal, 
    openModal, 
    closeModal, 
    setFile, 
    file, 
    insuranceProvider, 
    setInsuranceProvider,
    setModalError,
    modalError,
    checkForm,
    error,
    clearError
  } = bookingStore.protectionPlanStore;
  const history = useHistory();

  console.log("RENDER PROTECTION PLAN STEP ", modalError);

  useEffect(() => {
    setHistory(history);
  }, []);


  useEffect(() => {
    if (protectionPlans.length && !selectedPlan){
      const defaultProtection = protectionPlans.find((plan)=> plan.is_default);
      selectPlan(defaultProtection.id)
    }
  }, [protectionPlans]);

  const onNextHandler = (event) => {
    checkForm();
    nextStep(event);
  };

  const paymentClassName = classNames("payment-plan" , { error: !!error } );

  return (
    <div>
      <div className="step-container second">
        <div className={paymentClassName}>
          <div className="title">Select Protection Plan</div>
          <div className="plans-info">
              A protection plan offers coverage from losses like water damage, fire and more. We require that you select a plan unless you have your own private coverage. The protection plans we offer are all backed by SafeLease, <a style={{textDecoration: 'underline'}} href={BrochurePDF} download="Brochure.pdf">download a brochure with more information here</a>.
          </div>
          <div className="payment-cards">
            {protectionPlans
              .sort((a, b) => {
                if(a.price === 0) return 1;
                if(b.price === 0) return -1;
                return a.price - b.price;
              } )
              .map((plan, index) => (
                <PlanCard
                  key={index}
                  price={plan.price}
                  title={plan.title}
                  mostPopular={plan.most_popular}
                  description={plan.description}
                  isSelected={plan.id === selectedPlan?.id }
                  onClick={() => {
                    if(!plan.price) {
                      openModal();
                    }
                    selectPlan(plan.id);
                  }}
                />
              ))}
          </div>
          {error === 'Please specify your insurance company and/or upload an insurance document file.' ? <div className="error-msg">{error}<a onClick={
            (e) => {
              e.preventDefault();
              openModal();
            }
          }> ( Open )</a></div> : <div className="error-msg">{error}</div>}
          {!error && !!file && <div className="success-msg">{/* <FileIcon />*/}  File {file?.name} uploaded successfully.</div>}          
        </div>
      </div>
      <div className="navigation-btns">
        <Button text="Back" addClass="booking-navigation-btn back" onClick={prevStep} />
        <Button
          text="Continue"
          addClass="booking-navigation-btn continue"
          onClick={onNextHandler}
        />
      </div>
      {showModal && <UploadInsuranceModal onClose={closeModal} 
        onChangeFile={setFile} 
        onChangeInput={setInsuranceProvider}
        file={file} 
        error={modalError}
        insuranceProvider={insuranceProvider}
        onSubmit={() => {
          if(!!modalError) return;
          !file && setModalError('Please upload the file with the insurance document.');
          !insuranceProvider && setModalError('Please specify your insurance provider.');
          if(file && insuranceProvider){
            closeModal();
            clearError();
          }
        }}
      />}
    </div>
  );
});
