import React from 'react';

export default ({ label, text }) => {
  return (
    <div className="info-card">
      <div className="info-card-label">{label}</div>
      <div className="info-card-text">{text}</div>
    </div>
  );
};
