import React from 'react';
import Rating from './Rating';
import InfoCard from './InfoCard';
import AdditionalInfoCard from './AdditionalInfoCard';
import { Button } from './Button';
import BackButton from './BackButton';
import Carousel from './Carousel';
import { formatTelephone } from '../utils/fomatTelephone';

export default ({
  name,
  rating,
  address,
  details,
  accessHours,
  number,
  images,
  numberReviews,
  onBack,
  bookTourLink,
  location,
  directionLink,
}) => {
  return (
    <div className="location-info-container">
      <BackButton onClick={onBack} text="Back" />
      <div className="location-info-content">
        <h1 className="location-name">{name}</h1>
        <a href="#rating">
          {rating !== '-' && <Rating rating={rating || 5} label={`${numberReviews || ''} Reviews`} />}
        </a>
        <InfoCard label="Address:" text={address || 'No address.'} />
        <InfoCard label="Details:" text={details || 'No details.'} />
        <div className="location-bottom">
          <AdditionalInfoCard label="Access hours" text={accessHours} />
          <AdditionalInfoCard label="Call" text={formatTelephone(number)} link />
        </div>
        <div className="buttons-group">
          <a href="#prices">
            <Button text="See Prices" addClass="price-btn" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href={bookTourLink}>
            <Button text="Book a Tour" addClass="book-btn" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href={directionLink}>
            <Button text="Get Directions" addClass="book-btn get-directions" />
          </a>
        </div>
      </div>
      <Carousel photos={images} location={location} />
    </div>
  );
};
