import { observable, action, autorun, reaction, decorate } from "mobx";
import validator from "../../utils/validator";

import ContactStore from "./ContactStore";
import UnitDetailsStore from "./UnitDetailsStore";
import DocuSignStore from "./DocuSignStore";
import BillingStore from "./BillingStore";

import StorageService from "../../services/StorageService";
import { PROD, STAGE, SKIP_DOCUSIGN } from "../../config";
import ProtectionPlanStore from "./ProtectionPlanStore";

class BookingStore {
  step = 1;
  // local storage data
  bookingId = null;
  docusignReady = false;
  tenant = null;
  unitDetails = null;
  subscriptions = null;

  isFetching = false;
  isSuccessComplete = false;

  currentStepStore = null;
  locationPath = "";
  tenantSubscriptionId = null;

  constructor() {
    this.contactStore = new ContactStore(this);
    this.unitDetailsStore = new UnitDetailsStore(this);
    this.protectionPlanStore = new ProtectionPlanStore(this);
    this.docuSignStore = new DocuSignStore(this);
    this.billingStore = new BillingStore(this);
  }

  setLocationPath = (locationPath) => {
    this.locationPath = locationPath;
  };

  setTenantSubscriptionId = (id) => {
    this.tenantSubscriptionId = id;
  }

  setFetching = (bool) => {
    this.isFetching = bool;
  };

  setTenant = (data) => {
    StorageService.setItem("tenant", data);
    this.tenant = data;
  };

  setUnitDetails = (data) => {
    console.log("setUnitDetails", data);
    this.unitDetails = data;
  };

  setBookingId = (data) => {
    console.log("setBookingId", data);
    StorageService.setItem("bookingId", data);
    this.bookingId = data;
  };

  setSubscriptions = (data) => {
    StorageService.setItem("subscriptions", data);
    this.subscriptions = data;
  };

  getSubscriptions = () => {
    this.subscriptions = StorageService.getItem("subscriptions");
  };

  getBookingId = () => {
    this.bookingId = StorageService.getItem("bookingId");
  };

  getTenant = () => {
    this.tenant = StorageService.getItem("tenant");
  };

  getUnitDetails = () => {
    return this.unitDetails;
  };

  identifyCurrentData = () => {
    switch (this.step) {
      case 1:
        this.currentStepStore = this.unitDetailsStore;
        break;
      case 2:
        this.currentStepStore = this.protectionPlanStore;
        break;
      case 3:
        this.currentStepStore = this.contactStore;
        break;
      case 4:
        this.currentStepStore = this.billingStore;
        break;
      case 5:
        this.currentStepStore = this.docuSignStore;
        break;
      default:
        this.currentStepStore = this.contactStore;
    }
    this.currentStepInit();
  };

  currentStepInit = () => {
    try {
      if (this.currentStepStore.init) {
        if (this.step === 5) {
          if (!this.docusignReady) {
            this.docusignReady = true;
          } else {
            return;
          }
        } else {
          this.docusignReady = false;
        }
        this.currentStepStore.init();
      }
    } catch (error) {
      this.setStep(1);
    }
  };

  currentStepSubmit = async () => {
    console.log('currentStepSubmit');
    this.isFetching = true;
    if(this.step === 2) {
      const isSuccess = await this.currentStepStore.onSubmit();
      if(isSuccess){
        this.step++;
      }
      this.isFetching = false;
      return;
    }

    if (this.step === 4 ) {
      this.isFetching = false;
      const isSuccess = await this.currentStepStore.onSubmit();
      const hasFieldError = Object.keys(this.billingStore.fields).find(
        (field) => {
          return !!this.billingStore.fields[field].error;
        }
      );
      if (hasFieldError) return;
      if (!hasFieldError && isSuccess) {
        this.billingStore.history.push("/booking-success");
        this.step++;
      }
      this.isSuccessComplete = isSuccess;
      return;
    }
    
    await this.currentStepStore.onSubmit();

    this.isFetching = false;

    this.step++;
  };

  nextStep = async (event) => {
    event && event.preventDefault();

    const fields = this.currentStepStore.fields;
    const error = this.currentStepStore.error;
    if (!fields) {
      !error && this.currentStepSubmit();
      return;
    }

    Object.keys(fields).forEach(
      (field) =>
        fields[field].required &&
        (fields[field].error = validator.validationField(
          field,
          fields[field].value
        ))
    );

    const isErrors = this.checkForErrors(fields);

    if(this.step === 2){
      this.currentStepSubmit();
    }

    if (!isErrors) {
      this.currentStepSubmit();
    } else {
      const stepContainer = document.getElementById(`step-${this.step}`);
      if (stepContainer) {
        stepContainer.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  checkForErrors = (fields) =>
    !!Object.keys(fields).filter((field) => fields[field].error).length;

  prevStep = () => {
    this.step--;
  };

  setStep = (step) => {
    if (typeof step === "number") {
      this.step = step;
    }
  };
}

decorate(BookingStore, {
  step: observable,
  bookingId: observable,
  docusignReady: observable,
  tenant: observable,
  unitDetails: observable,
  subscriptions: observable,
  isFetching: observable,
  isSuccessComplete: observable,
  currentStepStore: observable,
  locationPath: observable,
  tenantSubscriptionId: observable,

  setLocationPath: action,
  setFetching: action,
  nextStep: action,
  prevStep: action,
  setStep: action,
});

const bookingStore = new BookingStore();

export default bookingStore;

autorun(bookingStore.identifyCurrentData);
