class Logger {
  oldConsoleLog = null;
  oldConsoleError = null;
  oldConsoleWarn = null;
  enable() {
    if (!this.oldConsoleLog || !this.oldConsoleError || !this.oldConsoleWarn) {
      return;
    }
    window.console.log = this.oldConsoleLog;
    window.console.error = this.oldConsoleError;
    window.console.warn = this.oldConsoleWarn;
  }
  disable() {
    this.oldConsoleLog = console.log;
    this.oldConsoleError = console.error;
    this.oldConsoleWarn = console.warn;
    window.console.log = () => {};
    window.console.error = () => {};
    window.console.warn = () => {};
  }
}

export default new Logger();
