import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={53}
            height={32}
            viewBox="0 0 53 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M.177.459h4.137v8.346h3.735v3.332H.177V.459zM8.575 7.986v-.13a4.38 4.38 0 014.68-4.525c2.6 0 4.649 1.617 4.649 4.41v.13a4.34 4.34 0 01-4.664 4.49c-2.588.002-4.665-1.484-4.665-4.375zm5.733-.081v-.128c0-1.16-.294-1.715-1.054-1.715-.728 0-1.083.506-1.083 1.666v.13c0 1.225.309 1.8 1.083 1.8.745-.005 1.054-.581 1.054-1.753zM18.366 7.986v-.13A4.243 4.243 0 0122.89 3.33c2.123 0 4.076.948 4.2 3.773h-3.3c-.076-.506-.17-.914-.8-.914-.727 0-1.007.474-1.007 1.6v.13c0 1.175.248 1.764 1.053 1.764.573 0 .868-.375.93-1.061h3.176c-.046 2.3-1.41 3.739-4.354 3.739-2.5 0-4.422-1.385-4.422-4.376zM27.74 9.766c0-2.024 1.41-2.793 4.432-2.793h.573v-.049c0-.538-.031-1.146-.728-1.146-.574 0-.683.559-.714.742h-3.285c.11-2.029 1.674-3.188 4.215-3.188 2.589 0 4.014 1.11 4.014 3.315v5.488h-3.41v-.964a2.46 2.46 0 01-2.34 1.192 2.485 2.485 0 01-2.758-2.597zm5.005-.63v-.351h-.685c-1.059 0-1.235.207-1.235.723 0 .37.314.6.823.6a.999.999 0 001.097-.973v.001zM37.751 0h3.517v12.135H37.75V0zM.177 19.3h4.137v8.346h3.735v3.332H.177V19.3zM8.575 26.83v-.132a4.379 4.379 0 014.68-4.523c2.6 0 4.649 1.616 4.649 4.41v.13a4.34 4.34 0 01-4.664 4.491c-2.588 0-4.665-1.486-4.665-4.377zm5.733-.082v-.13c0-1.16-.294-1.716-1.054-1.716-.728 0-1.083.506-1.083 1.666v.13c0 1.226.309 1.8 1.083 1.8.745-.002 1.054-.574 1.054-1.75zM18.366 26.829v-.131a4.242 4.242 0 014.524-4.523c2.123 0 4.076.946 4.2 3.773h-3.3c-.077-.506-.17-.914-.8-.914-.728 0-1.008.474-1.008 1.6v.13c0 1.175.25 1.764 1.054 1.764.573 0 .868-.376.93-1.061h3.176c-.046 2.3-1.41 3.739-4.354 3.739-2.5 0-4.422-1.388-4.422-4.377zM27.849 18.843h3.549v6.516l1.9-2.923h3.595l-2.551 3.772 2.72 4.769h-3.89l-1.774-3.12v3.12h-3.55V18.843z"
                fill="#E62222"
            />
            <path
                d="M36.48 26.83v-.132a4.411 4.411 0 014.68-4.523 4.1 4.1 0 014.37 4.491v.8h-5.487c.094.848.56 1.257 1.21 1.257.743 0 .96-.408.991-.542h3.27c-.187 1.817-1.612 3.025-4.386 3.025-2.665 0-4.648-1.518-4.648-4.377zm5.655-1.095c-.015-.7-.295-1.224-.976-1.224-.59 0-1.008.391-1.1 1.224h2.076zM46.643 22.436h3.547v1.78a2.464 2.464 0 012.589-1.88v3.48c-1.721-.13-2.59.457-2.59 1.437v3.724h-3.548l.002-8.54z"
                fill="#E62222"
            />
        </svg>
    )
}

export default SvgComponent
